app.directive('storeElement', ['$log', function($log) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/store_element/store_element.html',
        scope: {
            store: '=ngModel'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            // TODO: ha meglesz csinálva a store adatlap, törölni kell a commentet

            element.bind("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                Store.open(scope.store._id);
            })
        }
    };
}]);