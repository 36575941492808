app.filter("assignmentsSchemeFilter", ['moment', function(moment){
    return function(input, value, stores) {
        function hasMatch(s) {
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];

        if (input !== undefined) {
            input.map(function(_input){
                var obj = _input;
                if (obj !== null && (value === "" || value === null || hasMatch(obj.bu_code) || hasMatch(obj.start_date) || hasMatch(obj.end_date) || hasMatch(stores[obj.store_id].name))) {
                    matches.push(obj);
                }
            });
            return matches;

        }
    };
}]);

app.filter("assignmentsFeedbacksFilter", ['moment', function(moment){
    return function(input, value, stores) {
        function hasMatch(s) {
            console.log(s);
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];

        if (input !== undefined) {
            input.map(function(_input){
                var obj = _input;
                if (obj !== null && (value === "" || value === null || hasMatch(moment(obj.start).format('YYYY-MM-DD HH:mm') )|| hasMatch(moment(obj.end).format('YYYY-MM-DD HH:mm')) || hasMatch(stores[obj.store_id].name))) {
                    matches.push(obj);
                }
            });
            return matches;

        }
    };
}]);