app.controller('activateTokenController', ['$scope', '$state', '$stateParams', '$http', '$Services', '$log', '$loginRequest', function ($scope, $state, $stateParams, $http, $Services, $log, $loginRequest) {
    $log.info("activateToken", $stateParams);
    $scope.token = $stateParams.token;
    $scope.send = function () {
        var params = {
            'email': $scope.email,
            'token': $scope.token
        };
        $loginRequest.activatetoken(params).then(function (response) {
            $Services.toastMessage("Your new password has been sent via e-mail. Please log in and change it as soon as possible.");
            $state.go('login');
        }, function (msg, code) {
            $log.error(msg, code);
        });
    };
}]);