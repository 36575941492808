app.service('$assignmentsRequest', ['$http', '$request', '$q','$log', '$window', 'FileSaver', 'Blob', 'Upload',function($http, $request, $q, $log, $window, FileSaver, Blob, Upload){
    this.scheme = function(params) {
        var deferred = $q.defer();

        $log.debug("Get assignments scheme list", params);
        $request.getRequest("/api-v2/promoters/assignments/scheme", params)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };
    this.getScheme = function(params) {
        var deferred = $q.defer();

        $log.debug("Get assignment scheme", params);
        $request.getRequest("/api-v2/promoters/assignments/scheme/" + params )
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };
    this.setScheme = function(params) {
        var deferred = $q.defer();

        $log.debug("Set assignment scheme ", params);
        $request.postRequest("/api-v2/promoters/assignments/scheme", params)
            .then(function(data) {
                if (data.result == "OK") {
                    deferred.resolve(data);
                }
            });
        return deferred.promise;
    };

    //Times
    this.times = function(params) {
        var deferred = $q.defer();

        $log.debug("Get assignments times list", params);
        $request.getRequest("/api-v2/promoters/assignments/times", params)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    //Statements
    this.statements = function(id, params) {
        var deferred = $q.defer();

        $log.debug("Join to feedback", params);
        $request.postRequest("/api-v2/promoters/assignments/statements/" + id, params)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    this.assignmentDelete = function(id) {
        var deferred = $q.defer();

        $log.debug("Delete to feedback", id);
        $request.deleteRequest("/api-v2/promoters/assignments/times/" + id)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    this.assignmentEdit = function(id) {
        var deferred = $q.defer();

        $log.debug("Edit feedbackk", id);
        //$request.putRequest("/api-v2/promoters/assignments/times/" + id)
        $request.getRequest("/api-v2/test-confirm", {})
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    this.reloadFeedbackList = false;

}]);