app.controller('assignmentsJoinDialogController', ['$scope', '$log', '$assignmentsRequest', '$mdDialog', 'times', '$state', function ($scope, $log, $assignmentsRequest, $mdDialog, times, $state) {
    $log.info("assignmentsJoinDialogController", times);
    var userId = S.USER()._id;
    $scope.yesState = 1;

    if (times.statements !== null) {
        if (userId !== times.statements[0].user_id && Number(times.statements[0].statement) === 0) {
            $scope.yesState = 2;
        }
    }

    //Close dialog
    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.showYes = function() {
        if (times.statements !== null) {
            if (userId === times.statements[0].user_id) {
                if (Number(times.statements[0].statement) === 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    $scope.showNo = function() {
        if (times.statements !== null) {
            if (userId === times.statements[0].user_id) {
                if (Number(times.statements[0].statement) !== 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    $scope.load = function(statement) {
        var params = {};
        params.statement = statement;
        if ($scope.comment) params.comment = $scope.comment;
        if ($scope.assignmentsFeedbackJoin.$valid) {
            $assignmentsRequest.statements(times._id, params).then(function(data) {
                $mdDialog.hide();
            });
        }
    };

}]);