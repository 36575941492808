/*
 * @example
 * <div country ng-model="store"></div>
 */
app.directive('store', ['$User', '$Stores', function($User, $Stores) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/store/store.html',
        scope: {
            ngModel: "=",
            buCode: "=",
            countryCode: "=",
            channelId: "=",
            disabled:"=?",
            required:"="
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            if (scope.disabled === undefined) {
             scope.disabled = false;
            }
            scope.$watch('countryCode', function() {
                $Stores.getStores(scope.buCode, scope.countryCode, scope.channelId)
                    .then(function(res) {
                        scope.stores = res;
                    });
            });

            var stopModel = scope.$watch('ngModel', function(newVal) {
                if (newVal) {
                    scope.store = scope.ngModel;
                }
                stopModel();
            });

            scope.$watch('store', function(newVal) {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(newVal);
            });
        }
    };
}]);