app.controller('payrollAddController', ['$scope', '$state', '$Services', '$payrollRequest', '$log', '$mdDialog', '$http', function($scope, $state, $Services, $payrollRequest, $log, $mdDialog, $http) {
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.template = {};
    var date = new Date();
    $scope.year = date.getFullYear();
    $scope.month = date.getMonth() + 1;
    $scope.template.year = date.getFullYear();
    $scope.template.month = date.getMonth() + 1;
    //Default values
    $scope.selectedItem = null;
    $scope.searchText = null;

    //Close dialog
    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    //remove file object from scope
    $scope.cancelXlsx = function() {
        delete $scope.xlsFile;
    };

    $scope.sendForm = function() {
        if ($scope.xlsFile !== undefined) {
            $payrollRequest.importXLS($scope.xlsFile)
                .then(function() {
                    $mdDialog.hide();
                    Payroll.init();
                });
        } else if ($scope.payrollForm.$valid) {
            $log.debug("Add payroll from form");

            var params = {
                user_id: $scope.user.users__id,
                year: $scope.year,
                month: $scope.month,
                hours: $scope.hours,
                hourly_salary: $scope.hourly_salary,
                meeting_number: $scope.meeting_number,
                meeting_salary: $scope.meeting_salary,
                deduction: $scope.deduction,
                additional: $scope.additional,
                total_salary_net: $scope.total_netto,
                total_salary_gross: $scope.total_gross,
                remark: $scope.remark
            };

            $payrollRequest.addPayroll(params)
                .then(function() {
                    $mdDialog.hide();
                    Payroll.init();
                });
        }
    };

    $scope.sendTemplateForm = function(country, year, month) {
        $payrollRequest.getTemplate(country, year, month);
    };

}]);