app.service('$request_v1', ['$http', '$q', '$Services', '$log', function($http, $q, $Services, $log) {
    var that = this;
    this.postRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "POST",
            url: url,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param(params)
        }).then(function(response) {
            $Services.checkResponse_v1(response.data).then(function(data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error.result);
			});
        }, function(data) {
            // $log.error("Request error (%s): %s", data.result.code, data.result.message);
            $Services.toastMessage('Unknown error', 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };
}]);