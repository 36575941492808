app.service('$request', ['$http', '$q', '$Services', '$log', function($http, $q, $Services, $log) {
    var that = this;
    this.postRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "POST",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            data: params
        }).then(function(response) {
            $Services.checkResponse(response.data).then(function(data) {
                deferred.resolve(data);
            });
        }, function(error) {
            $log.error("Request error (%s): %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };
    this.getRequest = function(url, params) {
        var deferred = $q.defer();
        function call(u, pa) {
            $http({
                method: "GET",
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'X-MKEY-TOKEN': S.SSO()
                },
                params: params
            }).then(function (response) {
                function callback(p) {
                    angular.merge(params, p);
                    call(u, pa)
                }

                $Services.checkResponse(response.data, callback).then(function (data) {
                    deferred.resolve(data);
                });
            }, function (error) {
                var data = error.data;
                $log.error("Request error (%s): %s", data.result, data.reason);
                $Services.toastMessage(data.message, 'error');
                deferred.reject(data.reason);
            });
        }
        call(url, params);
        return deferred.promise;
    };

    this.deleteRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "DELETE",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            params: params
        }).then(function(response) {
            $Services.checkResponse(response.data).then(function(data) {
                deferred.resolve(data);
            });
        }, function(error) {
            var data = error.data;
            $log.error("Request error (%s): %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };
    this.putRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "PUT",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            params: params
        }).then(function(response) {
            $Services.checkResponse(response.data).then(function(data) {
                deferred.resolve(data);
            });
        }, function(error) {
            var data = error.data;
            $log.error("Request error (%s): %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };

    this.fileDownloadRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "GET",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            responseType: 'arraybuffer',
            params: params
        }).then(function(response) {
            var data = response.data;
            var fileParams = {
                content: data,
                name: response.headers('Content-Disposition').split('filename="')[1].split('"')[0]
            };
            deferred.resolve(fileParams);
        }, function(msg) {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(msg));
            var data = JSON.parse(decodedString);
            $log.error("Template download error[%s]: %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data);
        });
        return deferred.promise;
    };

    this.fileUploadRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "POST",
            url: url,
            headers: {
                'Content-Type': undefined,
                'X-MKEY-TOKEN': S.SSO()
            },
            data: params
        }).then(function(response) {
            var data = response.data;
            if (data.result == "OK") {
                deferred.resolve(data, headers);
            } else {
                deferred.reject(data);
                $log.error("Excel import error[%s]: %s", data.result, data.reason);
            }
        }, function(error) {
            $log.error("Excel import error[%s]: %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });
        return deferred.promise;
    };
}]);