app.controller('warningsController', ['$scope', '$http', '$q', '$log', function($scope, $http, $q, $log) {
    $log.info('warningsController loaded');
    $scope.warningsNameIcons = {
        "Mock location": "person_pin_circle"
    };
    //[{{item.date_created}}]
    var getWarnings = function() {
        var deferred = $q.defer();
        var service = $http({
            method: "post",
            url: "/api/warnings",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                sso: S.SSO()
            })
        });

        service.then(function(data) {
            deferred.resolve(data.data.result);
        });

        return deferred.promise;
    };

    getWarnings().then(function(res) {
        $scope.warningsList = res;

    });
}]);