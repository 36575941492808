/*
 * @param filter szűri a listát ország alapján
 * @param rank jogosultsági szintre szűr. Vesszővel több szintre is lehet szűrni (root,admin,promoter,kam)
 * @example
 * <div user ng-model="user"></div>
 */
app.directive('daytime', ['$log', '$User', '$Services', function($log, $User, $Services) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/daytime/daytime.html',
        scope: {
            daytime: '=ngModel',
            array: '=',
            last: '=',
            index: '=',
            form: '='
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            var user = $User.getUser(S.USER()._id);

            //TODO: Meg kell nézni miért használom itt az usert
            user.then(function(res) {
                scope.days = $Services.getDays();
            });

            scope.addDayTime = function() {
                scope.array.push({ day: "", time: "" });
            };

            scope.removeDayTime = function() {
                scope.array.splice(scope.index, 1);
            };

            scope.$watch('daytime', function() {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(scope.daytime);
            });

            scope.$watch('directive.$valid', function(newValue, oldValue) {
                ngModelCtrl.$setValidity('valid', newValue);
            });
        }
    };
}]);