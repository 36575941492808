app.controller('assignmentsFeedbacksDialogController', ['$scope', '$log', '$User', '$Services', '$assignmentsRequest', '$mdDialog', 'type', 'timeId', 'time', '$stateParams', '$state',  function ($scope, $log, $User, $Services, $assignmentsRequest, $mdDialog, type, timeId, time, $stateParams, $state) {
    $log.info("assignmentsFeedbacksDialogController", time, $stateParams);
    var params = [];
    $scope.type = type;
    $scope.editable = false;

    $scope.userCountries = null;
    $scope.userCountry = null;
    $scope.channels = null;


    if (type == "Edit") {
        $scope.editable = true;
    }
    $scope.getStatements = $Services.getStatements;

    var loadTime = function () {
        params._id = timeId;
        $assignmentsRequest.times(params).then(function (data) {
            load(data);
        });
    };

    var load = function (t) {
        $log.debug('Time data:', t);
        time = t.times[0];
        var store = t.stores[time.store_id];
        $scope.country = store.country_code;
        $scope.channel = store.channel_id;
        $scope.bu = time.bu_code;
        $scope.store = store._id;
        $scope.storeObject = store;
        $scope.start = new Date(time.start);
        $scope.minDate = $scope.start;
        $scope.end = new Date(time.end);
        $scope.created = new Date(time.date_created);
        $scope.modified = time.date_modified ? new Date(time.date_modified) : null;
        $scope.users = t.users;
        $scope.statements = time.statements;
        //$scope.statements = stetement;

        // $scope.$watch("country", function() {
        //     console.log("$scope.country", $scope.country);
        // });

        // $scope.$watch("channel", function() {
        //     console.log("$scope.channel", $scope.channel);

        // });

        // $scope.$watch("store", function() {
        //     console.log("$scope.store", $scope.store);

        // });
    };

    $scope.delete = function () {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this time?')
            .ok('Yes')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            $assignmentsRequest.assignmentDelete(timeId).then(function (data) {
                $state.transitionTo('feedbacks', {filters: $stateParams.filters}, {reload: true});
            });
        }, function () {
        });
    };

    $scope.editFeedback = function() {
        $scope.type = 'Edit';
        $scope.editable = true;
    };

    $scope.viewFeedback = function() {
        $scope.type = 'View';
        $scope.editable = false;
        //$state.go("viewTimes", {id: time._id, time: $stateParams.time, filters: $stateParams.filters});
    };

    $scope.saveFeedback = function() {
        $assignmentsRequest.assignmentEdit(timeId).then(function (data) {
            loadTime();
            $scope.viewFeedback();
        });
    };

    $scope.isAdmin = $Services.checkPermission(["AdminRole", "RootRole", "KamRole"]);
    $scope.showJoinIcon = function () {
        var show = false;
        if ($scope.weekFilter === moment().isoWeek() && !$scope.isAdmin) {
            show = true;
        }
        return show;
    };

    $scope.openJoinFeedback = function (ev) {
        $mdDialog.show({
                controller: "assignmentsJoinDialogController",
                templateUrl: '/frontend/ui/template/promoters/assignments_join_dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                multiple: true,
                locals: {
                    times: time
                }
            })
            .then(function () {
                loadTime();
                $assignmentsRequest.reloadFeedbackList = true;
            }, function () {
            });
    };


    //Close dialog
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    if (!time) {
        loadTime();
    } else {
        load(time);
    }
}]);