/*
 * Load Submenu
 */
app.controller('contentController', ['$scope', '$rootScope', '$state', '$mdMedia', '$mdDialog', '$Services', '$log', function($scope, $rootScope, $state, $mdMedia, $mdDialog, $Services, $log) {
    $log.info("contentController loaded");
    if ($Services.getUser()) {
        var _array = {};
        angular.forEach(menu, function(value1, key1) {
            var subArray = [];
            var parent = $state.current.name.split(".")[1] || $state.current.parent.split(".")[1];
            if (value1.url == parent) {
                angular.forEach(value1.menu, function(value2, key2) {
                    var _menu = [];
                    angular.forEach(value2.menu, function(value3, key3) {
                        if ($Services.checkPermission(value3.permission)) {
                            angular.extend(value3, { name: key3 });
                            _menu.push(value3);
                        }
                    });
                    if (_menu.length > 0) subArray.push({ title: key2, menus: _menu });
                });
                _array = { "parent": value1.url, "folder": value1.folder, "menu": subArray };
            }
        });
        $rootScope.subMenu = _array;
    }

    $scope.checkPermission = $Services.checkPermission;
}]);