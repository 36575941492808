app.service('$logoutRequest', ['$http', '$request_v1', '$q', '$log', function($http, $request_v1, $q, $log) {
    this.logout = function() {
        var deferred = $q.defer();
        $request_v1.postRequest("api/logout", {'sso': S.SSO()})
            .then(function(data) {
                deferred.resolve(data.result);
            }, function (error) {
                deferred.reject(error);
			});

        return deferred.promise;
    };
}]);