/*
 * @param filter: szűri a listát ország alapján
 * @example
 * <div channel ng-model="channel"></div>
 */
app.directive('channel', ['$log', '$Services', function($log, $Services) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/channel/channel.html',
        scope: {
            ngModel: '=',
            filter: '=',
            disabled: '=ngDisabled'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            var channelList = function(country) {
                var channels = $Services.getChannelByCountry(country);
                channels.then(function(res) {
                    scope.channels = res;
                });
            }

            scope.$watch('filter', function(newValue, oldValue) {
                if (newValue !== undefined) {
                    channelList(scope.filter);
                } else {
                    $log.error("Missing filter parameter");
                }
            });

            var stopModel = scope.$watch('ngModel', function(newVal) {
                if (newVal) {
                    scope.channel = scope.ngModel;
                }
                stopModel();
            });

            scope.$watch('channel', function(newVal) {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(newVal);
            });
        }
    };
}]);