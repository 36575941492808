app.service('$payrollRequest', ['$http', '$request', '$q', '$log', '$window', 'FileSaver', 'Blob', 'Upload', function($http, $request, $q, $log, $window, FileSaver, Blob, Upload) {
    this.getTemplate = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        var country_code = pCountry_code || "HU";
        $log.debug("Get payroll template(", pCountry_code, year, month, ")");

        //Így lehetőség van arra, hogy nevet adjunk a filenak (enélkül generált neve lenne)
        var saveFile = function(text, fileName) {
            var data = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(data, fileName);
        };

        $request.fileDownloadRequest("/api-v2/promoters/payroll/template/" + year + "/" + month, { country_code: country_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };

    this.importXLS = function(file) {
        var deferred = $q.defer();
        $log.debug("Import payroll from file ", file.name);

        var fd = new FormData();
        fd.append('payroll', file);
        $request.fileUploadRequest("/api-v2/promoters/payroll/import", fd)
            .then(function() {
                deferred.resolve();
            });
        return deferred.promise;
    };

    this.addPayroll = function(params) {
        var deferred = $q.defer();
        $log.debug("Add payroll ", params);
        $request.postRequest("/api-v2/promoters/payroll", params)
            .then(function() {
                deferred.resolve();
            });
        return deferred.promise;
    };

    this.getPayroll = function(params) {
        var deferred = $q.defer();
        $log.debug("Get payroll ", params);
        $request.postRequest("/api-v2/promoters/payroll", params)
            .then(function() {
                deferred.resolve();
            });
        return deferred.promise;
    };
}]);