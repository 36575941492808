app.controller('assignmentsController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$mdDialog', function ($scope, $state, $Services, $assignmentsRequest, $log, $mdDialog) {
    if ($state.current.name === "assignments") {
        $scope.currentNavItem = 'feedbacks';
    } else {
        $scope.currentNavItem = $state.current.name.split('.')[1] ? 'feedbacks' : $state.current.name;
    }
    switch ($scope.currentNavItem) {
        case 'feedbacks':
            $state.go("feedbacks");
            break;
        case 'scheme':
            $state.go("scheme");
            break;
    }
}]);