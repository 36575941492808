app.service('$loginRequest', ['$http', '$request_v1', '$q', '$log', function($http, $request_v1, $q, $log) {
    this.login = function(params) {
        var deferred = $q.defer();
        $request_v1.postRequest("api/login", params)
            .then(function(data) {
                deferred.resolve(data.result);
            }, function (error) {
                deferred.reject(error);
			});
        return deferred.promise;
    };
    this.activatetoken = function(params) {
        var deferred = $q.defer();
        $request_v1.postRequest("/api/userpwreset/trytoken", params)
            .then(function(data) {
                deferred.resolve(data.result);
            });
        return deferred.promise;
    };
    this.forgotpassword = function(params) {
        var deferred = $q.defer();
        $request_v1.postRequest("/api/userpwreset/gettoken", params)
            .then(function(data) {
                deferred.resolve(data.result);
            });
        return deferred.promise;
    };
}]);