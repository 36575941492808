app.controller('assignmentsSchemeController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$mdDialog', '$mdMedia',  function($scope, $state, $Services, $assignmentsRequest, $log, $mdDialog, $mdMedia) {
    $log.info("assignmentsSchemeController");
    var params = {};
    $scope.selected = [];

    $scope.query = {
        search: '',
        order: 'store_id'
    };

    $scope.removeSearch = function() {
        $scope.search.show = false;
        $scope.query.search = '';

        if ($scope.search.form.$dirty) {
            $scope.search.form.$setPristine();
        }
    };
    $scope.removeFilter = function() {
        $scope.filter.show = false;

        if ($scope.filter.form.$dirty) {
            $scope.filter.form.$setPristine();
        }
    };


    $scope.showAssignment = function(assignment) {
        $log.debug("Open dialog with params ", assignment);
        $mdDialog.show({
            controller: "assignmentsEditController",
            templateUrl: "/frontend/ui/template/promoters/assignments_edit.html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                assignment: assignment
            },
            onRemoving: function() {
                $state.transitionTo('main.assignments', null, { reload: true });
            }
        });
    };

    $scope.addSheme = function () {
        var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
        $mdDialog.show({
            controller: "assignmentsAddController",
            templateUrl: "/frontend/ui/template/promoters/assignments_add.html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            onRemoving: function () {
            },
            fullscreen: useFullScreen
        })
        .then(function () {
            load();
        }, function () {
            load();
        });
    };

    //betöltődéskor mindeképpen betöltődik
    $scope.$watch("country", function(newVal, oldVal) {
        if (newVal !== undefined) {
            params["store:country_code"] = newVal;
            load();
        }
    });

    var load = function() {
        $assignmentsRequest.scheme(params).then(function(data) {
            $scope.assignmentsList = data.schemes;
            $scope.assignmentsList.map(function (s) {
                s.store_name = data.stores[s.store_id].name;
            });
            $scope.stores = data.stores;
        });
    };
}]);