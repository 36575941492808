app.controller('assignmentsFeedbacksController', ['$scope', '$log', '$assignmentsRequest', 'moment', '$Services', '$mdDialog', '$state', '$stateParams',function ($scope, $log, $assignmentsRequest, moment, $Services, $mdDialog, $state, $stateParams) {
    $log.info("assignmentsFeedbacksController");
    //Default parameters
    var params = {};
    $scope.query = {
        search: '',
        order: 'store_id'
    };

    $scope.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);
    };

    $scope.isAdmin = $Services.checkPermission(["AdminRole", "RootRole", "KamRole"]);
    $scope.weekFilter = $stateParams.filters.week || moment().isoWeek();
    $scope.getStatements = $Services.getStatements;
    $scope.country = $stateParams.filters.country;

    $scope.years = CONFIG.YEARS;
    $scope.year = $stateParams.filters.year || moment().year();

    $scope.removeSearch = function () {
        $scope.search.show = false;
        $scope.query.search = '';

        if ($scope.search.form.$dirty) {
            $scope.search.form.$setPristine();
        }
    };

    $scope.removeFilter = function () {
        $scope.filter.show = false;

        if ($scope.filter.form.$dirty) {
            $scope.filter.form.$setPristine();
        }
    };

    function weekObject(week, year) {
        year = year || new Date().getFullYear();
        var mom = moment().year(year).isoWeek(week);

        this.num = week || mom.isoWeek();
        this.firstDay = mom.isoWeekday(1).toDate();
        this.lastDay = mom.isoWeekday(7).toDate();
    }

    var weekList = function (year) {
        var list = [];
        year = year || new Date().getFullYear();

        var mom = moment().year(year);

        for (var i = 1; i <= mom.isoWeeksInYear(); i++) {
            var week = {};
            week = new weekObject(i, year);
            list.push(week);
        }
        return list;
    };

    $scope.setWeeks = function (year) {
        $scope.weeks = weekList(year);
    };

    $scope.setWeeks();

    function TimeObject(time, store, users) {
        var timeArray = [];
        timeArray[0] = time;
        this.times = timeArray;
        var storeObject = {};
        storeObject[time.store_id] = store;
        this.stores = storeObject;
        this.users = users
    }
    $scope.openFeedback = function (id, time, store, users) {
        var time = new TimeObject(time, store, users);
        $state.go("viewTimes", {id: id, time: time, filters: {year: $scope.year, week: $scope.weekFilter, country: $scope.country}});
    };
    $scope.editFeedback = function (id, time, store, users) {
        var time = new TimeObject(time, store, users);
        //$state.go("editTimes", {id: id, time: time, filters: {year: $scope.year, week: $scope.weekFilter, country: $scope.country}});

        $mdDialog.show({
            controller: "assignmentsFeedbacksDialogController",
            templateUrl: "/frontend/ui/template/promoters/assignments_feedback_dialog.html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                type: "Edit",
                timeId: id,
                time: time
            }
        })
        .then(function () {
            //$state.transitionTo('feedbacks', {filters: $stateParams.filters}, {reload: true});
        }, function () {
        });
    };
    $scope.deleteFeedback = function (ev, id) {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this time?')
            .targetEvent(ev)
            .ok('Yes')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            $assignmentsRequest.assignmentDelete(id).then(function (data) {
                load();
            });
        }, function () {
        });
    };

    $scope.openJoinFeedback = function (ev, times) {
        ev.preventDefault();
        ev.stopPropagation();
        $mdDialog.show({
                controller: "assignmentsJoinDialogController",
                templateUrl: '/frontend/ui/template/promoters/assignments_join_dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    times: times
                }
            })
            .then(function () {
                load();
            }, function () {
            });
    };


    $scope.$watchGroup(["weekFilter", "country"], function (newVal, oldVal) {
        if (newVal[0] && newVal[1]) {
            load();
        }
    });



    $scope.$watch(function(){return $assignmentsRequest.reloadFeedbackList;}, function (newValue) {
        if (newValue) {
            load();
            $assignmentsRequest.reloadFeedbackList = false;
        }
    }, true);


    $scope.showJoinIcon = function (statementObject) {

        if (statementObject) {
            //A dialog controllerben is van egy ilyen metódus
            var show = false;
            if ($scope.weekFilter === moment().isoWeek() && !$scope.isAdmin) {
                show = true;
            }
            return show;
        }
        return true;
    };

    var load = function () {
        if ($scope.weekFilter !== undefined && $scope.country !== undefined) {
            var week = new weekObject($scope.weekFilter, $scope.year);
            params.start = '{' + moment(week.firstDay).format("YYYY-MM-DD") + '}:{' + moment(week.lastDay).format("YYYY-MM-DD") + '}';
            params.end = '{' + moment(week.firstDay).format("YYYY-MM-DD") + '}:{' + moment(week.lastDay).format("YYYY-MM-DD") + '}';
            params["store:country_code"] = $scope.country;
            $assignmentsRequest.times(params).then(function (data) {
                $scope.times = data.times;
                $scope.times.map(function(t) {
                    t.store_name = data.stores[t.store_id].name;
                });
                $scope.stores = data.stores;
                $scope.users = data.users;
            });
        }

    };
}]);