app.controller('loginController', ['$scope', '$log', '$Services', '$http', '$state', '$stateParams', '$loginRequest', function ($scope, $log, $Services, $http, $state, $stateParams, $loginRequest) {
	$log.info("Login");

	$scope.accounts = $Services.getAccounts();
	$scope.showFullLogin = false;
	$scope.showSimpleLogin = false;
	$scope.remove = false;

	$scope.empty = function (obj) {
		var size = 0, key;
		for (key in obj) {
			if (obj.hasOwnProperty(key)) size++;
		}
		return size === 0;
	};

	if ($scope.empty($scope.accounts)) {
		$scope.showFullLogin = true;
	}
	$scope.selected = {};

	$scope.setSelectedLogin = function (id, email, name) {
		if ($scope.remove) {
			delete $scope.accounts[id];
			$Services.deleteAccounts(id);
		} else {
			$scope.selected.id = id;
			$scope.selected.email = email;
			$scope.selected.name = name;
			delete $scope.selected.password;
			$scope.showSimpleLogin = true;
		}

	};


	var send = function (email, password) {
		$scope.loginProgress = true;
		var params = {
			'email': email,
			'hash': $Services.hash(email, password),
			'vendorid': '',
			'devicename': navigator.userAgent,
			'imsi': ''
		};
		$loginRequest.login(params).then(function (response) {
			$scope.loginProgress = false;
			$Services.setSSO(response.sso);
			$Services.setUser(response.user);
			$Services.setAccountChooser(response.user._id, email, response.user.name);
			$state.go(($stateParams.go !== null && $stateParams.go.name !== "login") ? $stateParams.go.name : "main", {}, {reload: true});
		}, function (error) {
			$scope.loginProgress = false;
			// csalás, mivel egyszerre úgyse látszanak ezek a felületek
			$scope.simplelogin.password.$setValidity('loginerror', false);
			$scope.login.email.$setValidity('loginerror', false);
			$scope.login.password.$setValidity('loginerror', false);
		});
	};

	$scope.send = function (email, password) {
		if (!$scope.login.password.$error.required && !$scope.login.email.$error.required) {
			send(email, password);
		}
	};
	$scope.simpleSend = function (email, password) {
		if (!$scope.simplelogin.$error.required) {
			send(email, password);
		}
	};

	$scope.forgotPassword = function () {
		$state.go('forgotPassword');
	};

}]);
