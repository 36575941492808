var permission = {
    "section_admin": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "users": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "user_add": [ "RootRole", "AdminRole", "KamRole", "MerchandiserRole" ],
    "contacts":[ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "contacts_add":[ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "configs": [ "RootRole", "AdminRole" ],
    "ratingsFormEdit": [ "RootRole", "AdminRole" ],
    "stats": [ "RootRole", "AdminRole", "KamRole" ],
    "stores": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MarketingRole", "MerchandiserRole", "PromoterRole" ],
    "stores_add": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "map": [ "All" ],
    "routeplan": [ "All" ],
    "photos": [ "All" ],
    "todo": [ "All" ],
    "app": [ "All" ],
    "meetings": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "meetings_add": [ "RootRole", "AdminRole", "KamRole" ],
    "assignments": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "feedbacks": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "scheme": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "payroll":[ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "payroll_add":[ "RootRole", "AdminRole", "KamRole" ]
};