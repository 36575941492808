/**
 * param: element name
 * usage: set-focus="elementName"
 * Az első megeggyező néven szereplő elemre ráteszi a fókuszt
 */
app.directive('setFocus', ['$timeout', '$window', function($timeout, $window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attr) {
            elem.bind('click', function() {
                $timeout(function() {
                    var element = $window.document.getElementsByName(attr.setFocus)[0];
                    if (element)
                        element.focus();
                });
            });
        }
    };
}]);