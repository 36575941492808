var app = angular.module('mKey', ['ui.router', 'ngAnimate', 'ngStorage', 'ngCookies', 'ngMdIcons', 'ngMaterial', 'ngMessages', 'ngFileUpload', 'mdColorPicker', 'ngFileSaver', 'angularMoment', 'md.data.table', 'angular-click-outside']);

app.config(["$stateProvider", "$urlRouterProvider", "$mdThemingProvider", "$mdDateLocaleProvider", "$locationProvider", "$qProvider", function ($stateProvider, $urlRouterProvider, $mdThemingProvider, $mdDateLocaleProvider, $locationProvider, $qProvider) {
    $locationProvider.html5Mode({
        enabled: false
    }).hashPrefix('');

    $urlRouterProvider.otherwise("");
    //------------------------
    //Login Required Pages
    //------------------------
    $stateProvider
        .state('main', {
            url: '',
            views: {
                "main": {
                    templateUrl: "/frontend/ui/template/main.html",
                    controller: "mainController"
                }
            }
        })
        .state('login', {
            url: '/login',
            params: {
                go: null
            },
            views: {
                "main": {
                    templateUrl: '/frontend/ui/template/login.html',
                    controller: "loginController"
                }
            }
        })
        .state("forgotPassword", {
            url: '/forgotpassword',
            views: {
                "main": {
                    templateUrl: "/frontend/ui/template/forgotPassword.html",
                    controller: "forgotPasswordController"
                }
            }
        })
        .state("activateToken", {
            url: '/activatetoken?token',
            views: {
                "main": {
                    templateUrl: "/frontend/ui/template/activateToken.html",
                    controller: "activateTokenController"
                }
            }
        })
        .state('user', {
            url: '/user/:id',
            parent: 'main',
            onEnter: ["$mdMedia", "$mdDialog", "$state", function ($mdMedia, $mdDialog, $state) {
                var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
                $mdDialog.show({
                    controller: "userController",
                    templateUrl: "/frontend/ui/template/salesAndRep/user.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    onRemoving: function () {
                        $state.go("payroll");
                    },
                    fullscreen: useFullScreen
                });
            }],

            onExit: ["$mdMedia", "$mdDialog", function ($mdMedia, $mdDialog) {
                $mdDialog.hide();
            }],
            views: {
                "dialog": {
                    data: {
                        permission: permission.user
                    }
                }
            }
        })
        .state('users', {
            url: '/users',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.users
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/users.html",
                    controller: "usersController"
                }
            }
        })
        .state(('userAdd'), {
            parent: "users",
            url: '/add',
            onEnter: ["$mdMedia", "$mdDialog", "$state", function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("users", "salesAndRep");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("users");
                }
            }],
            views: {
                "dialog": {
                    data: {
                        permission: permission.user_add
                    }
                }
            }
        })
        .state('contacts', {
            url: '/contacts',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.contacts
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/contacts.html",
                    controller: "contactsController"
                }
            }
        })
        .state(('contactAdd'), {
            parent: "contacts",
            url: '/add',
            onEnter: ["$mdMedia", "$mdDialog", "$state", function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("contacts", "salesAndRep");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("contacts");
                }
            }],
            views: {
                "dialog": {
                    data: {
                        permission: permission.contacts_add
                    }
                }
            }
        })
        .state('configs', {
            url: '/configs',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.configs
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/configs.html",
                    controller: "configsController"
                }
            }
        })
        .state('ratingsFormEdit', {
            url: '/ratingsFormEdit',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.ratingsFormEdit
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/ratingsFormEdit.html",
                    controller: "ratingsFormEditController"
                }
            }
        })
        .state('stats', {
            url: '/stats',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.stats
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/stats.html",
                    controller: "statsController"
                }
            }
        })
        .state('stores', {
            url: '/stores',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.stores
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/stores.html",
                    controller: "storesController"
                }
            }
        })
        .state('storeAdd', {
            url: '/add',
            parent: 'stores',
            onEnter: ["$mdMedia", "$mdDialog", "$state", function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("stores", "salesAndRep");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("stores");
                }
            }],
            views: {
                "dialog": {
                    data: {
                        permission: permission.stores_add
                    }
                }
            }
        })
        .state('map', {
            url: '/map',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.map
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/map.html",
                    controller: "mapController"
                }
            }
        })
        .state('routeplan', {
            url: '/routeplan',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.routeplan
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/routeplan.html",
                    controller: "routeplanController"
                }
            }
        })
        .state('photos', {
            url: '/photos',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.photos
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/photos.html",
                    controller: "photosController"
                }
            }
        })
        .state('todo', {
            url: '/todo?id',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.todo
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/task_and_issues.html",
                    controller: "todoController"
                }
            }
        })
        .state('apps', {
            url: '/apps',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.apps
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/apps.html",
                    controller: "appsController"
                }
            }
        })
        .state('meetings', {
            url: '/meetings',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.meetings
                    },
                    templateUrl: "/frontend/ui/template/promoters/meetings.html",
                    controller: "meetingsController"
                }
            }
        })
        .state('meetingsAdd', {
            url: '/add',
            parent: 'meetings',
            onEnter: ["$mdMedia", "$mdDialog", "$state", function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("meetings", "promoters");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("meetings");
                }
            }],
            views: {
                "dialog": {
                    data: {
                        permission: permission.meetings_add
                    }
                }
            }
        })
        .state('assignments', {
            url: '/assignments',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.assignments
                    },
                    templateUrl: "/frontend/ui/template/promoters/assignments.html",
                    controller: "assignmentsController"
                }
            }
        })

        .state('feedbacks', {
            url: '/feedbacks',
            parent: 'assignments',
            params: {
                reload: false,
                filters: {}
            },
            views: {
                "tabContent": {
                    data: {
                        permission: permission.feedbacks
                    },
                    templateUrl: "/frontend/ui/template/promoters/assignments_feedbacks.html",
                    controller: "assignmentsFeedbacksController"
                }
            }
        })
        .state("scheme", {
            parent: "assignments",
            url: '/scheme',
            views: {
                "tabContent": {
                    data: {
                        permission: permission.scheme
                    },
                    templateUrl: "/frontend/ui/template/promoters/assignments_scheme.html",
                    controller: "assignmentsSchemeController"
                }
            }
        })
        .state("viewTimes", {
            parent: "feedbacks",
            url: '/view/{id}',
            params: {
                time: null,
                filters: null
            },
            onEnter: ["$mdMedia", "$mdDialog", "$state", "$stateParams", function ($mdMedia, $mdDialog, $state, $stateParams) {
                var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
                $mdDialog.show({
                    controller: "assignmentsFeedbacksDialogController",
                    templateUrl: "/frontend/ui/template/promoters/assignments_feedback_dialog.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: useFullScreen,
                    locals: {
                        type: "View",
                        timeId: $stateParams.id,
                        time: $stateParams.time
                    }
                })
                    .then(function () {
                        //$state.transitionTo('feedbacks', {filters: $stateParams.filters}, {reload: true});
                    }, function () {
                        $state.transitionTo('feedbacks', {filters: $stateParams.filters});
                    });
            }]
        })
        .state("payroll", {
            parent: "main",
            url: '/payroll',
            views: {
                "content": {
                    data: {
                        permission: permission.payroll
                    },
                    templateUrl: "/frontend/ui/template/promoters/payroll.html",
                    controller: "payrollController"
                }
            }
        })
        .state("payrollAdd", {
            parent: 'payroll',
            url: '/add',
            onEnter: ["$mdMedia", "$mdDialog", "$state", function ($mdMedia, $mdDialog, $state) {
                var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
                $mdDialog.show({
                    controller: "payrollAddController",
                    templateUrl: "/frontend/ui/template/promoters/payroll_add.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    onRemoving: function () {
                        $state.go("payroll");
                    },
                    fullscreen: useFullScreen
                });
            }],

            onExit: ["$mdMedia", "$mdDialog", function ($mdMedia, $mdDialog) {
                $mdDialog.hide();
            }],
            views: {
                "dialog": {
                    controller: "payrollAddController",
                    data: {
                        permission: permission.payroll_add
                    }
                }
            }
        });


    // $mdThemingProvider.disableTheming();

    $mdThemingProvider.theme('error-toast');

    var lgRed = $mdThemingProvider.extendPalette('red', {
        '50': '#b10143',
        '100': '#b10143',
        '200': '#b10143',
        '300': '#b10143',
        '400': '#b10143',
        '500': '#b10143',
        '600': '#b10143',
        '700': '#b10143',
        '800': '#b10143',
        '900': '#b10143',
        'contrastDefaultColor': 'light'
    });

// Register the new color palette map with the name <code>neonRed</code>
    $mdThemingProvider.definePalette('lgRed', lgRed);

// Use that theme for the primary intentions
    $mdThemingProvider.theme('default')
        .primaryPalette('lgRed')
        .accentPalette('lgRed');

//set datepicker format
    $mdDateLocaleProvider.formatDate = function (date) {
        return moment(date).format('YYYY-MM-DD');
    };
}])
;

app.run(['$rootScope', '$state', '$mdMedia', '$mdDialog', '$Services', function ($rootScope, $state, $mdMedia, $mdDialog, $Services) {
    $rootScope.$on('$stateChangeStart', function (event, to) {
        //Ha nincs joga megnézni az oldalt autómatikusan a főoldalra irányítjuk
        if (to.data && to.data.permission && !$Services.checkPermission(to.data.permission)) {
            event.preventDefault();
            $state.go('main');
        }
    });
}]);

/*
 * Other Pages Contoller
 */
var permission = {
    "section_admin": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "users": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "user_add": [ "RootRole", "AdminRole", "KamRole", "MerchandiserRole" ],
    "contacts":[ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "contacts_add":[ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "configs": [ "RootRole", "AdminRole" ],
    "ratingsFormEdit": [ "RootRole", "AdminRole" ],
    "stats": [ "RootRole", "AdminRole", "KamRole" ],
    "stores": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MarketingRole", "MerchandiserRole", "PromoterRole" ],
    "stores_add": [ "RootRole", "AdminRole", "KamRole", "ManagerRole", "MerchandiserRole" ],
    "map": [ "All" ],
    "routeplan": [ "All" ],
    "photos": [ "All" ],
    "todo": [ "All" ],
    "app": [ "All" ],
    "meetings": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "meetings_add": [ "RootRole", "AdminRole", "KamRole" ],
    "assignments": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "feedbacks": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "scheme": [ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "payroll":[ "RootRole", "AdminRole", "KamRole", "PromoterRole" ],
    "payroll_add":[ "RootRole", "AdminRole", "KamRole" ]
};
app.controller('activateTokenController', ['$scope', '$state', '$stateParams', '$http', '$Services', '$log', '$loginRequest', function ($scope, $state, $stateParams, $http, $Services, $log, $loginRequest) {
    $log.info("activateToken", $stateParams);
    $scope.token = $stateParams.token;
    $scope.send = function () {
        var params = {
            'email': $scope.email,
            'token': $scope.token
        };
        $loginRequest.activatetoken(params).then(function (response) {
            $Services.toastMessage("Your new password has been sent via e-mail. Please log in and change it as soon as possible.");
            $state.go('login');
        }, function (msg, code) {
            $log.error(msg, code);
        });
    };
}]);
/*
 * Load Submenu
 */
app.controller('contentController', ['$scope', '$rootScope', '$state', '$mdMedia', '$mdDialog', '$Services', '$log', function($scope, $rootScope, $state, $mdMedia, $mdDialog, $Services, $log) {
    $log.info("contentController loaded");
    if ($Services.getUser()) {
        var _array = {};
        angular.forEach(menu, function(value1, key1) {
            var subArray = [];
            var parent = $state.current.name.split(".")[1] || $state.current.parent.split(".")[1];
            if (value1.url == parent) {
                angular.forEach(value1.menu, function(value2, key2) {
                    var _menu = [];
                    angular.forEach(value2.menu, function(value3, key3) {
                        if ($Services.checkPermission(value3.permission)) {
                            angular.extend(value3, { name: key3 });
                            _menu.push(value3);
                        }
                    });
                    if (_menu.length > 0) subArray.push({ title: key2, menus: _menu });
                });
                _array = { "parent": value1.url, "folder": value1.folder, "menu": subArray };
            }
        });
        $rootScope.subMenu = _array;
    }

    $scope.checkPermission = $Services.checkPermission;
}]);
app.controller('forgotPasswordController', ['$scope', '$state', '$http', '$Services', '$log', '$loginRequest', function ($scope, $state, $http, $Services, $log, $loginRequest) {
    $log.info("forgotPassword...");

    $scope.getToken = function (e, email) {
        $log.debug("Call getToken service");
        //ToDo: felül kell vizsgálni, hogy erre szükség van e
        e.preventDefault();
        $http({
            method: "post",
            url: "/api/userpwreset/gettoken",
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            data: $.param({
                'email': email
            })
        }).then(function (response) {
            var data = response.data;
            if (S.OK(data)) {
                alertify.alert("Email sent");
                setTimeout(function () {
                    $state.go('main.mkey');
                }, 3000);
            }
        }, function (msg, code) {
            $log.error(msg, code);
        });
    };
    $scope.send = function () {
        $loginRequest.forgotpassword({'email': $scope.email}).then(function (response) {
            $Services.toastMessage("The password recovery e-mail has been sent. Please follow the instructions.");
            $state.go('activateToken');
        }, function (msg, code) {
            $log.error(msg, code);
        });
    };
}]);
app.controller('loginController', ['$scope', '$log', '$Services', '$http', '$state', '$stateParams', '$loginRequest', function ($scope, $log, $Services, $http, $state, $stateParams, $loginRequest) {
	$log.info("Login");

	$scope.accounts = $Services.getAccounts();
	$scope.showFullLogin = false;
	$scope.showSimpleLogin = false;
	$scope.remove = false;

	$scope.empty = function (obj) {
		var size = 0, key;
		for (key in obj) {
			if (obj.hasOwnProperty(key)) size++;
		}
		return size === 0;
	};

	if ($scope.empty($scope.accounts)) {
		$scope.showFullLogin = true;
	}
	$scope.selected = {};

	$scope.setSelectedLogin = function (id, email, name) {
		if ($scope.remove) {
			delete $scope.accounts[id];
			$Services.deleteAccounts(id);
		} else {
			$scope.selected.id = id;
			$scope.selected.email = email;
			$scope.selected.name = name;
			delete $scope.selected.password;
			$scope.showSimpleLogin = true;
		}

	};


	var send = function (email, password) {
		$scope.loginProgress = true;
		var params = {
			'email': email,
			'hash': $Services.hash(email, password),
			'vendorid': '',
			'devicename': navigator.userAgent,
			'imsi': ''
		};
		$loginRequest.login(params).then(function (response) {
			$scope.loginProgress = false;
			$Services.setSSO(response.sso);
			$Services.setUser(response.user);
			$Services.setAccountChooser(response.user._id, email, response.user.name);
			$state.go(($stateParams.go !== null && $stateParams.go.name !== "login") ? $stateParams.go.name : "main", {}, {reload: true});
		}, function (error) {
			$scope.loginProgress = false;
			// csalás, mivel egyszerre úgyse látszanak ezek a felületek
			$scope.simplelogin.password.$setValidity('loginerror', false);
			$scope.login.email.$setValidity('loginerror', false);
			$scope.login.password.$setValidity('loginerror', false);
		});
	};

	$scope.send = function (email, password) {
		if (!$scope.login.password.$error.required && !$scope.login.email.$error.required) {
			send(email, password);
		}
	};
	$scope.simpleSend = function (email, password) {
		if (!$scope.simplelogin.$error.required) {
			send(email, password);
		}
	};

	$scope.forgotPassword = function () {
		$state.go('forgotPassword');
	};

}]);

/*
 * Main page controller
 * Ezekhez az oldalakhoz kell login
 */
app.controller('mainController', ['$scope', '$rootScope', '$state', '$Services', '$User', '$log', '$logoutRequest', function($scope, $rootScope, $state, $Services, $User, $log, $logoutRequest){
    $log.info("Main loaded");
    $scope.userPhoto = "/frontend/ui/pics/user_fake.png";
    $scope.userPhotoMedium = "/frontend/ui/pics/user_fake.png";
	$scope.user = {};
    //Todo: át kell írni
    $rootScope.$watch("loggedInUserId", function(){
        var user = $Services.getUser();
        if(user != null){
            $scope.user = user;
            $scope.userName = user.name;
            $scope.userId = user._id;
            $scope.userEmail = user.email;
            $scope.userPhoto = "/api/userphoto/" + $scope.userId + "/small/circle";
            $scope.userPhotoMedium = "/api/userphoto/" + $scope.userId + "/medium/circle";

            $scope.logout = function(event) {
                delete $rootScope.loggedInUserId;
				$logoutRequest.logout();
                $Services.deleteUser();
				$state.go("login");
                return EVENT.PREVENT(event);
            };

            $scope.openUser = function(event, id) {
                User.open(event, id);
            };

            $scope.permission = permission;
            $scope.checkPermission = $Services.checkPermission;
            $scope.isAdmin = $Services.isLoggedInUserAdmin;

        } else {
            $state.go("login", { go: $state.current });
            $Services.showLogin();
        }
    });
}]);
/*
 * MKey Controller
 * */
app.controller('mkeyController', ['$scope', '$rootScope', function($scope, $rootScope){
}]);
/*
 * Promoters Controller
 * */
app.controller('promotersController', ['$scope', '$rootScope', function($scope, $rootScope){
}]);

app.controller('usersController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("users", "salesAndRep");


    $scope.checkPermission = $Services.checkPermission(permission.user_add);
    $scope.openUserAdd = function () {
        $state.go("userAdd");
    }
}]);
app.controller('warningsController', ['$scope', '$http', '$q', '$log', function($scope, $http, $q, $log) {
    $log.info('warningsController loaded');
    $scope.warningsNameIcons = {
        "Mock location": "person_pin_circle"
    };
    //[{{item.date_created}}]
    var getWarnings = function() {
        var deferred = $q.defer();
        var service = $http({
            method: "post",
            url: "/api/warnings",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                sso: S.SSO()
            })
        });

        service.then(function(data) {
            deferred.resolve(data.data.result);
        });

        return deferred.promise;
    };

    getWarnings().then(function(res) {
        $scope.warningsList = res;

    });
}]);
/**
 * param: element name
 * usage: set-focus="elementName"
 * Az első megeggyező néven szereplő elemre ráteszi a fókuszt
 */
app.directive('setFocus', ['$timeout', '$window', function($timeout, $window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attr) {
            elem.bind('click', function() {
                $timeout(function() {
                    var element = $window.document.getElementsByName(attr.setFocus)[0];
                    if (element)
                        element.focus();
                });
            });
        }
    };
}]);
app.filter("assignmentsSchemeFilter", ['moment', function(moment){
    return function(input, value, stores) {
        function hasMatch(s) {
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];

        if (input !== undefined) {
            input.map(function(_input){
                var obj = _input;
                if (obj !== null && (value === "" || value === null || hasMatch(obj.bu_code) || hasMatch(obj.start_date) || hasMatch(obj.end_date) || hasMatch(stores[obj.store_id].name))) {
                    matches.push(obj);
                }
            });
            return matches;

        }
    };
}]);

app.filter("assignmentsFeedbacksFilter", ['moment', function(moment){
    return function(input, value, stores) {
        function hasMatch(s) {
            console.log(s);
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];

        if (input !== undefined) {
            input.map(function(_input){
                var obj = _input;
                if (obj !== null && (value === "" || value === null || hasMatch(moment(obj.start).format('YYYY-MM-DD HH:mm') )|| hasMatch(moment(obj.end).format('YYYY-MM-DD HH:mm')) || hasMatch(stores[obj.store_id].name))) {
                    matches.push(obj);
                }
            });
            return matches;

        }
    };
}]);
app.filter("storeFilter", [function(){
    return function(input, value, array) {
        function hasMatch(s) {
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];
        input.map(function(_input){
            var obj = _input;
            array.map(function(arr) {
                if (_input == arr) {
                    obj = null;
                }
            });
            if (obj !== null && (value === "" || value === null || hasMatch(obj.stores_name) || hasMatch(obj.stores_address) || hasMatch(obj.stores_city))) {
                matches.push(obj);
            }
        });
        return matches;
    };
}]);
app.filter("userFilter", [function(){
    return function(input, value, array) {
        function hasMatch(s) {
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];
        input.map(function(_input){
            var obj = _input;
            array.map(function(arr) {
                if (_input == arr) {
                    obj = null;
                }
            });
            if (obj !== null && (value === "" || value === null || hasMatch(obj.users_name))) {
                matches.push(obj);
            }
        });
        return matches;
    };
}]);
app.filter("weekFormatFilter", ['moment', function(moment) {
    return function(input, value, array) {
        var space = "\u00A0";
        if (input.num.toString().length === 1) space += "\u00A0\u00A0";
        return input.num + space + " (" + moment(input.firstDay).format("MMM D") + " - " + moment(input.lastDay).format("MMM D") + ")";
    };
}]);
app.service('$request', ['$http', '$q', '$Services', '$log', function($http, $q, $Services, $log) {
    var that = this;
    this.postRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "POST",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            data: params
        }).then(function(response) {
            $Services.checkResponse(response.data).then(function(data) {
                deferred.resolve(data);
            });
        }, function(error) {
            $log.error("Request error (%s): %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };
    this.getRequest = function(url, params) {
        var deferred = $q.defer();
        function call(u, pa) {
            $http({
                method: "GET",
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'X-MKEY-TOKEN': S.SSO()
                },
                params: params
            }).then(function (response) {
                function callback(p) {
                    angular.merge(params, p);
                    call(u, pa)
                }

                $Services.checkResponse(response.data, callback).then(function (data) {
                    deferred.resolve(data);
                });
            }, function (error) {
                var data = error.data;
                $log.error("Request error (%s): %s", data.result, data.reason);
                $Services.toastMessage(data.message, 'error');
                deferred.reject(data.reason);
            });
        }
        call(url, params);
        return deferred.promise;
    };

    this.deleteRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "DELETE",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            params: params
        }).then(function(response) {
            $Services.checkResponse(response.data).then(function(data) {
                deferred.resolve(data);
            });
        }, function(error) {
            var data = error.data;
            $log.error("Request error (%s): %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };
    this.putRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "PUT",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            params: params
        }).then(function(response) {
            $Services.checkResponse(response.data).then(function(data) {
                deferred.resolve(data);
            });
        }, function(error) {
            var data = error.data;
            $log.error("Request error (%s): %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };

    this.fileDownloadRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "GET",
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-MKEY-TOKEN': S.SSO()
            },
            responseType: 'arraybuffer',
            params: params
        }).then(function(response) {
            var data = response.data;
            var fileParams = {
                content: data,
                name: response.headers('Content-Disposition').split('filename="')[1].split('"')[0]
            };
            deferred.resolve(fileParams);
        }, function(msg) {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(msg));
            var data = JSON.parse(decodedString);
            $log.error("Template download error[%s]: %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data);
        });
        return deferred.promise;
    };

    this.fileUploadRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "POST",
            url: url,
            headers: {
                'Content-Type': undefined,
                'X-MKEY-TOKEN': S.SSO()
            },
            data: params
        }).then(function(response) {
            var data = response.data;
            if (data.result == "OK") {
                deferred.resolve(data, headers);
            } else {
                deferred.reject(data);
                $log.error("Excel import error[%s]: %s", data.result, data.reason);
            }
        }, function(error) {
            $log.error("Excel import error[%s]: %s", data.result, data.reason);
            $Services.toastMessage(data.message, 'error');
            deferred.reject(data.reason);
        });
        return deferred.promise;
    };
}]);
app.service('$request_v1', ['$http', '$q', '$Services', '$log', function($http, $q, $Services, $log) {
    var that = this;
    this.postRequest = function(url, params) {
        var deferred = $q.defer();
        $http({
            method: "POST",
            url: url,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param(params)
        }).then(function(response) {
            $Services.checkResponse_v1(response.data).then(function(data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error.result);
			});
        }, function(data) {
            // $log.error("Request error (%s): %s", data.result.code, data.result.message);
            $Services.toastMessage('Unknown error', 'error');
            deferred.reject(data.reason);
        });

        return deferred.promise;
    };
}]);
app.factory('$Services', ["$log", "$q", "$http", "$sessionStorage", "$localStorage", "$cookies", "$state", "$rootScope", "$mdToast", "$mdDialog", "$window", function($log, $q, $http, $sessionStorage, $localStorage, $cookies, $state, $rootScope, $mdToast, $mdDialog, $window) {
    var _service = null;
    var that = {};

    function b64EncodeUnicode(str) {
        return $window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    function b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call($window.atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    that.getServices = function() {
        if (_service === null) {
            $log.debug("get service from $http");
            var deferred = $q.defer();
            $http({
                method: "post",
                url: "api/services",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                data: $.param({
                    sso: S.SSO()
                })

            }).then(function(response) {
                var data = response.data;
                deferred.resolve(data.result);
                _service = data.result;
            }, function(msg) {
                deferred.reject(msg);
                $log.error(msg, code);
            });

            return deferred.promise;
        } else {
            $log.debug("get service from global value");
            return _service;
        }
    };
    that.getUser = function() {
        if ($sessionStorage.user !== undefined) {
            $rootScope.loggedInUserId = $sessionStorage.user._id;
        }
        //$log.debug("getUser: ", $sessionStorage.user.users_name);
        return $sessionStorage.user;
    };
    that.setUser = function(user) {
        $log.debug("Save user:", user._id);
        $rootScope.loggedInUserId = user._id;
        $rootScope.loggedInUserRank = user.rank;
        //S.saveUser(user);
        $sessionStorage.user = user;
    };
    that.deleteUser = function() {
        $log.debug("Delete user");
        delete $sessionStorage.user;
    };
    that.getSSO = function() {
        var sso = $cookies.get("sso");
        return sso !== null ? sso : '';
    };

    that.setAccountChooser = function (id, email, name) {
        var idsBase64 = $localStorage.ACCOUNTS;
        var ids = idsBase64 ? JSON.parse(b64DecodeUnicode(idsBase64)) : {};

        ids[id] = {};
        ids[id].name = name;
        ids[id].email = email;
        ids[id].last = new Date();

        $localStorage.ACCOUNTS = b64EncodeUnicode(JSON.stringify(ids));
    };

    that.getAccounts = function () {
        var idsBase64 = $localStorage.ACCOUNTS;
        var ids = idsBase64 ? JSON.parse(b64DecodeUnicode(idsBase64)) : {};

        return ids;
    };

    that.deleteAccounts = function (id) {
        var idsBase64 = $localStorage.ACCOUNTS;
        var ids = idsBase64 ? JSON.parse(b64DecodeUnicode(idsBase64)) : {};

        delete ids[id];

        $localStorage.ACCOUNTS = b64EncodeUnicode(JSON.stringify(ids));
    };

    /*
     * Megvizsgálja, hogy a user admin-e
     */
    that.isLoggedInUserAdmin = function() {
        var _userRank = that.getUser().rank;
        var _ret = false;
        if (_userRank == "AdminRole" || _userRank == "RootRole") {
            _ret = true;
        }

        return _ret;
    };
    that.setSSO = function(sso) {
        S.saveSSO(sso);
        //$cookies.put("sso", sso, { expires : '5' });
    };
    that.login = function(e) {
        $log.info("Login...");
        //ToDo: át kell írni más formára a logint!!!
        e.preventDefault();
        var email = $("#login form input[name=email]").val();
        var password = $("#login form input[name=password]").val();
        var hash = S.hash(email, password);
        var devicename = navigator.userAgent;
        $http({
            method: "post",
            url: "api/login",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'email': email,
                'hash': hash,
                'vendorid': '',
                'devicename': devicename,
                'imsi': ''
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                that.setSSO(data.result.sso);
                that.setUser(data.result.user);
                $state.go($state.current, {}, { reload: true });

                //ToDo: undi de muszáj
                $("#login").hide();
            }
        });
    };
    that.forgotPassword = function() {
        $state.go('forgotPassword');
    };
    that.showLogin = function() {
        if (!that.getUser()) {
            $("#login").fadeIn(1000);
            $("#forgotPassword").hide();
            $("#tryToken").hide();
            $("#login form").unbind('submit');
            $("#login form").submit(that.login);
            $("#login form .forgPassLink").click(function() {
                that.forgotPassword();
            });
        }
    };
    that.checkOK = function(r, s) {
        if (r === null || r.status === null) return false;
        var alertNeeded = r.status != "OK";
        var loginNeeded = s == 401 || s == 411;
        if (alertNeeded) {
            var message = CONFIG.VALIDATION_STRING[r.result.message] ? CONFIG.VALIDATION_STRING[r.result.message] : r.result.message;
            alertify.alert(message);
        }
        if (loginNeeded) {
            $cookies.remove('sso');
            $sessionStorage.$reset();
            S.showLogin();
        }
        return !(alertNeeded || loginNeeded);
    };

    /*
     * @param: permArray (Array[])
     * Megvizsgálja, hogy a user jogosultsága benne van e a menü jogosultságában
     */
    that.checkPermission = function(permArray) {
        var _userRank = that.getUser().rank;
        var _ret = false;
        for (var i in permArray) {
            if (permArray[i] == _userRank || permArray[i] == "All") {
                _ret = true;
            }
        }

        return _ret;
    };

    that.getChannelByCountry = function(countryCode) {
        var deferred = $q.defer();
        if (countryCode !== undefined) {
            $http({
                method: "post",
                url: "api/channels",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                params: {
                    'country_code': countryCode
                },
                data: $.param({
                    'sso': S.SSO()
                })
            }).then(function(response) {
                var data = response.data;
                if (S.OK(data)) {
                    deferred.resolve(data.result[countryCode]);
                } else {
                    $log.error(data.result.code + ": " + data.result.message);
                    deferred.reject('Status: error');
                }
            }, function(msg, code) {
                deferred.reject(msg);
                $log.error(msg, code);
            });
        } else {
            deferred.reject('getChannelByCountry: undefined parameter');
        }
        return deferred.promise;
    };

    that.toastMessage = function(text, type) {

        var toast = $mdToast.simple()
            .textContent(text)
            .action('OK')
            .highlightAction(false)
            .hideDelay('6000')
            .position('right');
        if (type !== undefined) {
            toast.theme(type + "-toast");
        }
        $mdToast.show(toast);
    };

    that.yearList = function() {
        var _arr = [];
        var _firstYear = 2015;
        var _actualYear = new Date().getFullYear();
        for (var i = _actualYear; i >= _firstYear; i--) {
            _arr.push(i);
        }
        return _arr;
    };

    that.getDays = function() {
        return CONFIG.DAYS;
    };

    that.getStatements = function(statement) {
        var statements = ['Cancelled', 'Reserved', 'Ideiglenes'];
        return statements[statement];
    };

    that.checkResponse = function(data, callback) {
        var deferred = $q.defer();
        if (data.result == "OK") {
            deferred.resolve(data);
        } else if (data.result == "CONFIRM_NEEDED") {
            DialogController.$inject = ["$scope", "$mdDialog", "data"];
            function DialogController($scope, $mdDialog, data) {
                $scope.message = data.message;
                $scope.options = data.options;

                $scope.hide = function(params) {
                    if (params) {
                        $mdDialog.hide(params);
                    } else {
                        $mdDialog.cancel();
                    }

                };

                $scope.cancel = function() {
                    $mdDialog.cancel();
                };
            }

            $mdDialog.show({
                    controller: DialogController,
                    templateUrl: '/frontend/ui/template/confirmNeeded.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    multiple: true,
                    locals: {
                        data: data
                    }
                })
                .then(function (p) {
                    callback(p);
                }, function () {
                });
        } else{
            deferred.reject(data);
            $log.error("Request error (%s): %s", data.result, data.reason);
            that.toastMessage(data.message, 'error');
        }
        return deferred.promise;
    };


    that.checkResponse_v1 = function(data) {
        var deferred = $q.defer();
        if (data.status == "OK") {
            deferred.resolve(data);
        } else{
            deferred.reject(data);
            $log.error("Request error (%s): %s", data.result, data.reason);

            var message = CONFIG.VALIDATION_STRING[data.result.message] ? CONFIG.VALIDATION_STRING[data.result.message] : data.result.message;
            that.toastMessage(message, 'error');
        }
        return deferred.promise;
    };

    that.hash = function(email, password){
        var sourceC = email.toLowerCase().replace(/[^a-z0-9]/ig, '') + password + "12345hat7";
        return Crypto.SHA256(sourceC) + Crypto.SHA1(sourceC);
    };
    return that;
}]);
/**
 * @param bu_code
 * @param country_code
 * @param channel_id
 */
app.service("$Stores", ['$http', '$q', function($http, $q) {
    var deferred;
    this.getStores = function(bu_code, country_code, channel_id) {
        var params = {};

        if (country_code !== undefined && country_code !== 0) params.country_code = country_code;
        if (channel_id !== undefined && channel_id !== 0) params.channel_id = channel_id;
        if (bu_code !== undefined && bu_code !== 0) params.bu_code = bu_code;

        if (deferred) {
            deferred.resolve("Store call cancelled and recall");
        }
        deferred = $q.defer();
        $http({
            method: "post",
            url: "api/storelist",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: params,
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };

}]);
app.factory('$User', ['$http', '$q', '$log', '$sessionStorage', function($http, $q, $log, $sessionStorage) {
    var that = {};
    that.getUser = function(id) {
        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/userlist",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: {
                'user_id': id
            },
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(error) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };
    that.getAll = function() {
        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/userlist",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(error) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };
    that.getLoggedInUser = function() {
        var deferred = $q.defer();
        var user = that.getUser(S.USER()._id);


        if ($sessionStorage.loggedInUser !== undefined) {
            $log.debug("Get logged in user from session");
            deferred.resolve($sessionStorage.loggedInUser);
        } else {
            $log.debug("Get logged in user from api");
            user.then(function(res) {
                $sessionStorage.loggedInUser = res;
                deferred.resolve(res);
            });
        }
        return deferred.promise;
    };

    that.isAdmin = function(user) {
        return (user.users_rank == "AdminRole") || (user.users_rank == "RootRole");
    };

    that.getCountries = function(user) {
        var that = this;
        var countriesList = user.assigned_countries.split(',');
        var userCountries = [];
        angular.forEach(CONFIG.COUNTRIES, function(k, v) {
            if (user.assigned_countries !== null && !that.isAdmin(user)) {
                angular.forEach(countriesList, function(l, x) {
                    if (l == v) {
                        userCountries.push({ name: k, value: v });
                    }
                });
            } else {
                userCountries.push({ name: k, value: v });
            }
        });
        return userCountries;
    };
    that.getBUs = function(user) {
        var that = this;
        var buList = user.bu_list.split(',');
        var userBUs = [];
        angular.forEach(CONFIG.BUSINESS_UNITS, function(k, v) {
            if (user.bu_list !== null && !that.isAdmin(user)) {
                angular.forEach(buList, function(l, x) {
                    if (x == k) {
                        userBUs.push({ name: k, value: v });
                    }
                });
            } else {
                userBUs.push({ name: k, value: v });
            }
        });
        return userBUs;
    };
    return that;
}]);
app.controller('assignmentsAddController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$User', '$Stores', '$http', '$filter', '$mdDialog', 'moment', '$timeout', '$interval', function($scope, $state, $Services, $assignmentsRequest, $log, $User, $Stores, $http, $filter, $mdDialog, moment, $timeout, $interval) {
    console.log("assignmentsAddController");
    var user = $User.getUser(S.USER()._id);

    //Default form values
    $scope.selectedItem = null;
    $scope.searchText = null;
    $scope.progress = false;
    $scope.assignment = {};
    $scope.assignment.country = null;
    $scope.assignment.channel = 0;
    $scope.assignment.bu = null;
    $scope.assignment.selectedStores = [];
    $scope.assignment.allDay = true;
    $scope.minDate = new Date();
    $scope.maxDate = moment().add(3, 'M').endOf("month").toDate();
    $scope.assignment.startDate = new Date();
    $scope.assignment.endDate = new Date(
        $scope.assignment.startDate.getFullYear(),
        $scope.assignment.startDate.getMonth(),
        $scope.assignment.startDate.getDate() + 14
    );
    $scope.userCountries = null;
    $scope.userCountry = null;
    $scope.channels = null;

    $scope.daytimes = [
        { day: "", startTime: "", endTime: "" }
    ];

    user.then(function(res) {
        $scope.userCountries = $User.getCountries(res);
        $scope.assignment.country = res.users_country_code;
        $scope.userBUs = $User.getBUs(res);
        $scope.days = $Services.getDays();
        $scope.assignment.bu = $scope.userBUs[0].value;
    });

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.sendForm = function() {
        $log.debug("sending...", $scope.assignment);

        if ($scope.assignmentForm.$valid) {
            $scope.progress = true;
            $scope.progressState = 0;
            $scope.progressIndex = 0;
            $scope.progressText = "";
            var progress = function(c, count) {
                var x = 100 / count;
                $scope.progressState += x;
                $scope.progressText = c + "/" + count;
                if ($scope.progressState === 100) $scope.progressText = "Complete";
            };

            var success = 0;
            $scope.assignment.selectedStores.map(function(store, index) {
                $scope.progress = true;
                var params = {
                    "store_id": store.stores__id,
                    "bu_code": $scope.assignment.bu,
                    "start_date": $filter('date')($scope.assignment.startDate, "yyyy-MM-dd"),
                    "end_date": $filter('date')($scope.assignment.endDate, "yyyy-MM-dd"),
                    "days": {}
                };

                for (var i in $scope.daytimes) {
                    var daytime = $scope.daytimes[i];
                    if (params.days[daytime.day] === undefined) {
                        params.days[daytime.day] = [];
                    }
                    var duration = moment.duration(moment(daytime.endTime).diff(daytime.startTime));
                    var hours = duration.asMinutes();
                    params.days[daytime.day].push({ 'start': $filter('date')(daytime.startTime, 'HH:mm:ss'), 'duration': hours });
                }

                $assignmentsRequest.setScheme(params).then(function() {
                    success++;
                    var _index = index + 1;
                    if (success == $scope.assignment.selectedStores.length) {
                        $timeout(function() { $mdDialog.hide(); }, 3000);
                    }
                    progress(_index, $scope.assignment.selectedStores.length);
                });
            });
        }


    };

    $scope.toggleButton = function(toggle, day) {
        $scope.assignment.weekday = $scope.assignment.weekday || [];
        if (toggle) {
            $scope.assignment.weekday.push(day);
        } else {
            for (var i in $scope.assignment.weekday) {
                if ($scope.assignment.weekday[i] == day) {
                    $scope.assignment.weekday.splice(i, 1);
                }
            }
        }
    };


    //watchers
    $scope.$watchGroup(['assignment.country', 'assignment.channel', 'assignment.bu'], function(newValues, oldValue) {
        if (newValues[0] !== null) {
            var channels = $Services.getChannelByCountry(newValues[0]);
            channels.then(function(res) {
                $scope.channels = res;
            });
        }
        if (newValues[0] !== null && newValues[1] !== null && newValues[2] !== null) {
            $Stores.getStores(newValues[2], newValues[0], newValues[1])
                .then(function(res) {
                    $scope.stores = res;
                });
        }
    });

    var storeValidator = function() {
        $scope.storeValidation = {};
        if ($scope.assignment.selectedStores === undefined || $scope.assignment.selectedStores.length === 0) {
            $scope.storeValidation.required = true;
        } else {
            $scope.storeValidation.required = false;
        }
    };


    $scope.$watch('assignment.selectedStores', function(newvalue, oldvalue) {
        storeValidator();
    }, true);

    var dayValidator = function() {
        $scope.dayValidation = {};
        if (($scope.assignment.weekday && $scope.assignment.weekday.length > 0) || $scope.assignment.allDay) {
            if ($scope.assignment.weekday !== undefined) console.log($scope.assignment.weekday.length);
            $scope.dayValidation.required = false;
        } else {
            $scope.dayValidation.required = true;
        }
    };
    $scope.$watch('assignment.weekday', function(newvalue, oldvalue) {
        dayValidator();
    }, true);
    $scope.$watch('assignment.allDay', function(newvalue, oldvalue) {
        dayValidator();
    });
}]);
app.controller('assignmentsController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$mdDialog', function ($scope, $state, $Services, $assignmentsRequest, $log, $mdDialog) {
    if ($state.current.name === "assignments") {
        $scope.currentNavItem = 'feedbacks';
    } else {
        $scope.currentNavItem = $state.current.name.split('.')[1] ? 'feedbacks' : $state.current.name;
    }
    switch ($scope.currentNavItem) {
        case 'feedbacks':
            $state.go("feedbacks");
            break;
        case 'scheme':
            $state.go("scheme");
            break;
    }
}]);
app.controller('assignmentsEditController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$User', '$Stores', '$http', '$filter', '$mdDialog', 'moment', '$timeout', 'assignment', function($scope, $state, $Services, $assignmentsRequest, $log, $User, $Stores, $http, $filter, $mdDialog, moment, $timeout, assignment) {
    $log.debug("assignmentsEditController ", assignment);
    var user = $User.getUser(S.USER()._id);

    //Default form values
    $scope.selectedItem = null;
    $scope.searchText = null;
    $scope.progress = false;
    $scope.userCountries = null;
    $scope.userCountry = null;
    $scope.channels = null;
    $scope.assignment = assignment;
    $scope.daytimes = [];


    var load = function() {
        $assignmentsRequest.getScheme(assignment._id).then(function(data) {

            //$scope.assignment = data;

            $scope.minDate = new Date(data.start_date);
            $scope.assignment.startDate = new Date(data.start_date);
            $scope.assignment.endDate = new Date(data.end_date);
            $scope.assignment.created = new Date(data.date_created);
            $scope.assignment.modified = data.date_modified ? new Date(data.date_modified) : null;

            $scope.assignment.user_created = data.user_created;
            $scope.assignment.user_modified = data.user_modified ? data.user_modified : null;

            $scope.assignment.store = data.store;
            $scope.assignment.users = data.users;
            angular.forEach(data.days, function(value, key) {

                for (var dayParams in value) {
                    var param = {};

                    param.day = key;
                    param.startTime = moment(value[dayParams].start, 'HH:mm:ss+Z').toDate();
                    param.endTime = moment(param.startTime).add(value[dayParams].duration, 'm').toDate();

                    $scope.daytimes.push(param);
                }

                //console.log(value);

            });
        });
    };

    load();

    $scope.searchInJSON = function(array, keyName, key) {
        var returnValue = "";
        angular.forEach(array, function(value) {
            if (value[keyName] == key) {
                returnValue = value;
            }
        });
        return returnValue;
    };

    user.then(function(res) {
        $scope.userCountries = $User.getCountries(res);
        $scope.assignment.country = res.users_country_code;
        $scope.userBUs = $User.getBUs(res);
        $scope.days = $Services.getDays();
        $scope.assignment.bu = $scope.userBUs[0].value;
    });

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.sendForm = function() {
        $log.debug("sending...", $scope.assignment);

        if ($scope.assignmentForm.$valid) {
            $scope.progress = true;
            $scope.progressState = 0;
            $scope.progressIndex = 0;
            $scope.progressText = "";
            var progress = function(c, count) {
                var x = 100 / count;
                $scope.progressState += x;
                $scope.progressText = c + "/" + count;
                if ($scope.progressState === 100) $scope.progressText = "Complete";
            };

            var success = 0;
            $scope.assignment.selectedStores.map(function(store, index) {
                $scope.progress = true;
                var params = {
                    "store_id": store.stores__id,
                    "bu_code": $scope.assignment.bu,
                    "start_date": $filter('date')($scope.assignment.startDate, "yyyy-MM-dd"),
                    "end_date": $filter('date')($scope.assignment.endDate, "yyyy-MM-dd"),
                    "days": {}
                };

                for (var i in $scope.daytimes) {
                    var daytime = $scope.daytimes[i];
                    if (params.days[daytime.day] === undefined) {
                        params.days[daytime.day] = [];
                    }
                    var duration = moment.duration(moment(daytime.endTime).diff(daytime.startTime));
                    var hours = duration.asMinutes();
                    params.days[daytime.day].push({ 'start': $filter('date')(daytime.startTime, 'HH:mm:ss'), 'duration': hours });
                }

                $assignmentsRequest.setScheme(params).then(function() {
                    success++;
                    var _index = index + 1;
                    if (success == $scope.assignment.selectedStores.length) {
                        $timeout(function() { $mdDialog.hide(); }, 3000);
                    }
                    progress(_index, $scope.assignment.selectedStores.length);
                });
            });
        }


    };

    $scope.toggleButton = function(toggle, day) {
        $scope.assignment.weekday = $scope.assignment.weekday || [];
        if (toggle) {
            $scope.assignment.weekday.push(day);
        } else {
            for (var i in $scope.assignment.weekday) {
                if ($scope.assignment.weekday[i] == day) {
                    $scope.assignment.weekday.splice(i, 1);
                }
            }
        }
    };


    //watchers
    $scope.$watchGroup(['assignment.country', 'assignment.channel', 'assignment.bu'], function(newValues, oldValue) {
        if (newValues[0] !== undefined) {
            var channels = $Services.getChannelByCountry(newValues[0]);
            channels.then(function(res) {
                $scope.channels = res;
            });
        }
        if (newValues[0] !== null && newValues[1] !== null && newValues[2] !== null) {
            $Stores.getStores(newValues[2], newValues[0], newValues[1])
                .then(function(res) {
                    $scope.stores = res;
                });
        }
    });

    var storeValidator = function() {
        $scope.storeValidation = {};
        if ($scope.assignment.selectedStores === undefined || $scope.assignment.selectedStores.length === 0) {
            $scope.storeValidation.required = true;
        } else {
            $scope.storeValidation.required = false;
        }
    };


    $scope.$watch('assignment.selectedStores', function(newvalue, oldvalue) {
        storeValidator();
    }, true);

    var dayValidator = function() {
        $scope.dayValidation = {};
        if (($scope.assignment.weekday && $scope.assignment.weekday.length > 0) || $scope.assignment.allDay) {
            if ($scope.assignment.weekday !== undefined) console.log($scope.assignment.weekday.length);
            $scope.dayValidation.required = false;
        } else {
            $scope.dayValidation.required = true;
        }
    };
    $scope.$watch('assignment.weekday', function(newvalue, oldvalue) {
        dayValidator();
    }, true);
    $scope.$watch('assignment.allDay', function(newvalue, oldvalue) {
        dayValidator();
    });
}]);
app.controller('assignmentsFeedbacksController', ['$scope', '$log', '$assignmentsRequest', 'moment', '$Services', '$mdDialog', '$state', '$stateParams',function ($scope, $log, $assignmentsRequest, moment, $Services, $mdDialog, $state, $stateParams) {
    $log.info("assignmentsFeedbacksController");
    //Default parameters
    var params = {};
    $scope.query = {
        search: '',
        order: 'store_id'
    };

    $scope.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);
    };

    $scope.isAdmin = $Services.checkPermission(["AdminRole", "RootRole", "KamRole"]);
    $scope.weekFilter = $stateParams.filters.week || moment().isoWeek();
    $scope.getStatements = $Services.getStatements;
    $scope.country = $stateParams.filters.country;

    $scope.years = CONFIG.YEARS;
    $scope.year = $stateParams.filters.year || moment().year();

    $scope.removeSearch = function () {
        $scope.search.show = false;
        $scope.query.search = '';

        if ($scope.search.form.$dirty) {
            $scope.search.form.$setPristine();
        }
    };

    $scope.removeFilter = function () {
        $scope.filter.show = false;

        if ($scope.filter.form.$dirty) {
            $scope.filter.form.$setPristine();
        }
    };

    function weekObject(week, year) {
        year = year || new Date().getFullYear();
        var mom = moment().year(year).isoWeek(week);

        this.num = week || mom.isoWeek();
        this.firstDay = mom.isoWeekday(1).toDate();
        this.lastDay = mom.isoWeekday(7).toDate();
    }

    var weekList = function (year) {
        var list = [];
        year = year || new Date().getFullYear();

        var mom = moment().year(year);

        for (var i = 1; i <= mom.isoWeeksInYear(); i++) {
            var week = {};
            week = new weekObject(i, year);
            list.push(week);
        }
        return list;
    };

    $scope.setWeeks = function (year) {
        $scope.weeks = weekList(year);
    };

    $scope.setWeeks();

    function TimeObject(time, store, users) {
        var timeArray = [];
        timeArray[0] = time;
        this.times = timeArray;
        var storeObject = {};
        storeObject[time.store_id] = store;
        this.stores = storeObject;
        this.users = users
    }
    $scope.openFeedback = function (id, time, store, users) {
        var time = new TimeObject(time, store, users);
        $state.go("viewTimes", {id: id, time: time, filters: {year: $scope.year, week: $scope.weekFilter, country: $scope.country}});
    };
    $scope.editFeedback = function (id, time, store, users) {
        var time = new TimeObject(time, store, users);
        //$state.go("editTimes", {id: id, time: time, filters: {year: $scope.year, week: $scope.weekFilter, country: $scope.country}});

        $mdDialog.show({
            controller: "assignmentsFeedbacksDialogController",
            templateUrl: "/frontend/ui/template/promoters/assignments_feedback_dialog.html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                type: "Edit",
                timeId: id,
                time: time
            }
        })
        .then(function () {
            //$state.transitionTo('feedbacks', {filters: $stateParams.filters}, {reload: true});
        }, function () {
        });
    };
    $scope.deleteFeedback = function (ev, id) {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this time?')
            .targetEvent(ev)
            .ok('Yes')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            $assignmentsRequest.assignmentDelete(id).then(function (data) {
                load();
            });
        }, function () {
        });
    };

    $scope.openJoinFeedback = function (ev, times) {
        ev.preventDefault();
        ev.stopPropagation();
        $mdDialog.show({
                controller: "assignmentsJoinDialogController",
                templateUrl: '/frontend/ui/template/promoters/assignments_join_dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    times: times
                }
            })
            .then(function () {
                load();
            }, function () {
            });
    };


    $scope.$watchGroup(["weekFilter", "country"], function (newVal, oldVal) {
        if (newVal[0] && newVal[1]) {
            load();
        }
    });



    $scope.$watch(function(){return $assignmentsRequest.reloadFeedbackList;}, function (newValue) {
        if (newValue) {
            load();
            $assignmentsRequest.reloadFeedbackList = false;
        }
    }, true);


    $scope.showJoinIcon = function (statementObject) {

        if (statementObject) {
            //A dialog controllerben is van egy ilyen metódus
            var show = false;
            if ($scope.weekFilter === moment().isoWeek() && !$scope.isAdmin) {
                show = true;
            }
            return show;
        }
        return true;
    };

    var load = function () {
        if ($scope.weekFilter !== undefined && $scope.country !== undefined) {
            var week = new weekObject($scope.weekFilter, $scope.year);
            params.start = '{' + moment(week.firstDay).format("YYYY-MM-DD") + '}:{' + moment(week.lastDay).format("YYYY-MM-DD") + '}';
            params.end = '{' + moment(week.firstDay).format("YYYY-MM-DD") + '}:{' + moment(week.lastDay).format("YYYY-MM-DD") + '}';
            params["store:country_code"] = $scope.country;
            $assignmentsRequest.times(params).then(function (data) {
                $scope.times = data.times;
                $scope.times.map(function(t) {
                    t.store_name = data.stores[t.store_id].name;
                });
                $scope.stores = data.stores;
                $scope.users = data.users;
            });
        }

    };
}]);
app.controller('assignmentsFeedbacksDialogController', ['$scope', '$log', '$User', '$Services', '$assignmentsRequest', '$mdDialog', 'type', 'timeId', 'time', '$stateParams', '$state',  function ($scope, $log, $User, $Services, $assignmentsRequest, $mdDialog, type, timeId, time, $stateParams, $state) {
    $log.info("assignmentsFeedbacksDialogController", time, $stateParams);
    var params = [];
    $scope.type = type;
    $scope.editable = false;

    $scope.userCountries = null;
    $scope.userCountry = null;
    $scope.channels = null;


    if (type == "Edit") {
        $scope.editable = true;
    }
    $scope.getStatements = $Services.getStatements;

    var loadTime = function () {
        params._id = timeId;
        $assignmentsRequest.times(params).then(function (data) {
            load(data);
        });
    };

    var load = function (t) {
        $log.debug('Time data:', t);
        time = t.times[0];
        var store = t.stores[time.store_id];
        $scope.country = store.country_code;
        $scope.channel = store.channel_id;
        $scope.bu = time.bu_code;
        $scope.store = store._id;
        $scope.storeObject = store;
        $scope.start = new Date(time.start);
        $scope.minDate = $scope.start;
        $scope.end = new Date(time.end);
        $scope.created = new Date(time.date_created);
        $scope.modified = time.date_modified ? new Date(time.date_modified) : null;
        $scope.users = t.users;
        $scope.statements = time.statements;
        //$scope.statements = stetement;

        // $scope.$watch("country", function() {
        //     console.log("$scope.country", $scope.country);
        // });

        // $scope.$watch("channel", function() {
        //     console.log("$scope.channel", $scope.channel);

        // });

        // $scope.$watch("store", function() {
        //     console.log("$scope.store", $scope.store);

        // });
    };

    $scope.delete = function () {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this time?')
            .ok('Yes')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            $assignmentsRequest.assignmentDelete(timeId).then(function (data) {
                $state.transitionTo('feedbacks', {filters: $stateParams.filters}, {reload: true});
            });
        }, function () {
        });
    };

    $scope.editFeedback = function() {
        $scope.type = 'Edit';
        $scope.editable = true;
    };

    $scope.viewFeedback = function() {
        $scope.type = 'View';
        $scope.editable = false;
        //$state.go("viewTimes", {id: time._id, time: $stateParams.time, filters: $stateParams.filters});
    };

    $scope.saveFeedback = function() {
        $assignmentsRequest.assignmentEdit(timeId).then(function (data) {
            loadTime();
            $scope.viewFeedback();
        });
    };

    $scope.isAdmin = $Services.checkPermission(["AdminRole", "RootRole", "KamRole"]);
    $scope.showJoinIcon = function () {
        var show = false;
        if ($scope.weekFilter === moment().isoWeek() && !$scope.isAdmin) {
            show = true;
        }
        return show;
    };

    $scope.openJoinFeedback = function (ev) {
        $mdDialog.show({
                controller: "assignmentsJoinDialogController",
                templateUrl: '/frontend/ui/template/promoters/assignments_join_dialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                multiple: true,
                locals: {
                    times: time
                }
            })
            .then(function () {
                loadTime();
                $assignmentsRequest.reloadFeedbackList = true;
            }, function () {
            });
    };


    //Close dialog
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    if (!time) {
        loadTime();
    } else {
        load(time);
    }
}]);
app.controller('assignmentsJoinDialogController', ['$scope', '$log', '$assignmentsRequest', '$mdDialog', 'times', '$state', function ($scope, $log, $assignmentsRequest, $mdDialog, times, $state) {
    $log.info("assignmentsJoinDialogController", times);
    var userId = S.USER()._id;
    $scope.yesState = 1;

    if (times.statements !== null) {
        if (userId !== times.statements[0].user_id && Number(times.statements[0].statement) === 0) {
            $scope.yesState = 2;
        }
    }

    //Close dialog
    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.showYes = function() {
        if (times.statements !== null) {
            if (userId === times.statements[0].user_id) {
                if (Number(times.statements[0].statement) === 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    $scope.showNo = function() {
        if (times.statements !== null) {
            if (userId === times.statements[0].user_id) {
                if (Number(times.statements[0].statement) !== 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    $scope.load = function(statement) {
        var params = {};
        params.statement = statement;
        if ($scope.comment) params.comment = $scope.comment;
        if ($scope.assignmentsFeedbackJoin.$valid) {
            $assignmentsRequest.statements(times._id, params).then(function(data) {
                $mdDialog.hide();
            });
        }
    };

}]);
app.controller('assignmentsSchemeController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$mdDialog', '$mdMedia',  function($scope, $state, $Services, $assignmentsRequest, $log, $mdDialog, $mdMedia) {
    $log.info("assignmentsSchemeController");
    var params = {};
    $scope.selected = [];

    $scope.query = {
        search: '',
        order: 'store_id'
    };

    $scope.removeSearch = function() {
        $scope.search.show = false;
        $scope.query.search = '';

        if ($scope.search.form.$dirty) {
            $scope.search.form.$setPristine();
        }
    };
    $scope.removeFilter = function() {
        $scope.filter.show = false;

        if ($scope.filter.form.$dirty) {
            $scope.filter.form.$setPristine();
        }
    };


    $scope.showAssignment = function(assignment) {
        $log.debug("Open dialog with params ", assignment);
        $mdDialog.show({
            controller: "assignmentsEditController",
            templateUrl: "/frontend/ui/template/promoters/assignments_edit.html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                assignment: assignment
            },
            onRemoving: function() {
                $state.transitionTo('main.assignments', null, { reload: true });
            }
        });
    };

    $scope.addSheme = function () {
        var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
        $mdDialog.show({
            controller: "assignmentsAddController",
            templateUrl: "/frontend/ui/template/promoters/assignments_add.html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            onRemoving: function () {
            },
            fullscreen: useFullScreen
        })
        .then(function () {
            load();
        }, function () {
            load();
        });
    };

    //betöltődéskor mindeképpen betöltődik
    $scope.$watch("country", function(newVal, oldVal) {
        if (newVal !== undefined) {
            params["store:country_code"] = newVal;
            load();
        }
    });

    var load = function() {
        $assignmentsRequest.scheme(params).then(function(data) {
            $scope.assignmentsList = data.schemes;
            $scope.assignmentsList.map(function (s) {
                s.store_name = data.stores[s.store_id].name;
            });
            $scope.stores = data.stores;
        });
    };
}]);
app.controller('assignments_oldController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', function($scope, $state, $Services, $assignmentsRequest, $log){
    M.loadContent('assignments_old','promoters');
}]);
app.controller('meetingsController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent('meetings','promoters');

    $scope.checkPermission = $Services.checkPermission(permission.meetings_add);
    $scope.openMeetingAdd = function () {
        $state.go("meetingsAdd");
    }
}]);
app.controller('payrollAddController', ['$scope', '$state', '$Services', '$payrollRequest', '$log', '$mdDialog', '$http', function($scope, $state, $Services, $payrollRequest, $log, $mdDialog, $http) {
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.template = {};
    var date = new Date();
    $scope.year = date.getFullYear();
    $scope.month = date.getMonth() + 1;
    $scope.template.year = date.getFullYear();
    $scope.template.month = date.getMonth() + 1;
    //Default values
    $scope.selectedItem = null;
    $scope.searchText = null;

    //Close dialog
    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    //remove file object from scope
    $scope.cancelXlsx = function() {
        delete $scope.xlsFile;
    };

    $scope.sendForm = function() {
        if ($scope.xlsFile !== undefined) {
            $payrollRequest.importXLS($scope.xlsFile)
                .then(function() {
                    $mdDialog.hide();
                    Payroll.init();
                });
        } else if ($scope.payrollForm.$valid) {
            $log.debug("Add payroll from form");

            var params = {
                user_id: $scope.user.users__id,
                year: $scope.year,
                month: $scope.month,
                hours: $scope.hours,
                hourly_salary: $scope.hourly_salary,
                meeting_number: $scope.meeting_number,
                meeting_salary: $scope.meeting_salary,
                deduction: $scope.deduction,
                additional: $scope.additional,
                total_salary_net: $scope.total_netto,
                total_salary_gross: $scope.total_gross,
                remark: $scope.remark
            };

            $payrollRequest.addPayroll(params)
                .then(function() {
                    $mdDialog.hide();
                    Payroll.init();
                });
        }
    };

    $scope.sendTemplateForm = function(country, year, month) {
        $payrollRequest.getTemplate(country, year, month);
    };

}]);
app.controller('payrollController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent('payroll','promoters');

    $scope.checkPermission = $Services.checkPermission(permission.payroll_add);
    $scope.openPayrollAdd = function () {
        console.log("add")
        $state.go("payrollAdd");
    }
}]);
app.controller('appsController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("apps", "salesAndRep");
}]);
app.controller('configsController', ['$scope', '$state', '$User', '$Services', 'Upload', '$mdDialog', '$http', '$q', '$log', function($scope, $state, $User, $Services, Upload, $mdDialog, $http, $q, $log) {
    var user = $User.getUser(S.USER()._id);


    $scope.userCountries = null;
    $scope.channels = null;
    $scope.country = null;
    $scope.newChannel = false;
    $scope.monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    $scope.month = new Date().getMonth() + 1;
    $scope.year = new Date().getFullYear();
    $scope.yearList = $Services.yearList();
    $scope.channel = {};
    $scope.countryTranslator = CONFIG.COUNTRIES;
    user.then(function(res) {
        $scope.userCountries = $User.getCountries(res);
        $scope.country = res.users_country_code;
    });

    //Channels module

    var loadChannels = function(country) {
        var channels = $Services.getChannelByCountry(country);
        channels.then(function(res) {
            $scope.channels = res;
        });
    };

    $scope.showChannel = function(_channel) {
        $scope.newChannel = false;
        _channel = _channel || {};
        $scope.channel.id = _channel._id || '';
        $scope.channel.name = _channel.name || '';
        $scope.channel.color = _channel.color || '#621A4A';
        $scope.channel.parent = _channel.parent || 0;
        $scope.channel.country = _channel.country_code || $scope.country;
        $scope.channel.store_visits_in_year = Number(_channel.store_visits_in_year) || 1;
        $scope.channel.default_visit_time = Number(_channel.default_visit_time) || 1;
    };

    $scope.cancelImage = function() {
        delete $scope.channel.icon;
    };

    $scope.createNewChannel = function() {
        $scope.showChannel();
        $scope.newChannel = true;
    };


    var confirmDialog = function() {
        var confirm = $mdDialog.confirm()
            .title('You not selected icon to channel! Dou you want to use the default icon?')
            //.textContent('You not selected icon to channel! Dou you want to use the default icon?')
            .ok('Yes')
            .cancel('No');
        return $mdDialog.show(confirm);
    };

    var sendChannel = function() {
        var params = $scope.channel;
        params.sso = S.SSO();
        Upload.upload({
            url: '/api/addeditchannel',
            data: $scope.channel
        }).then(function(response) {
            var resp = response.data;
            loadChannels($scope.country);
            $Services.toastMessage("Channel added");
            $log.debug(resp);
        }, function(response) {
            var resp = response.data;
            $Services.toastMessage(resp.status, 'error');
            $log.error(resp);
        });
    };

    $scope.saveChannel = function() {
        if ($scope.channelForm.$valid) {
            $scope.channel.use_default_icon = true;
            if ($scope.channel.file !== undefined) {
                $scope.channel.use_default_icon = false;
                sendChannel();
            } else {
                if ($scope.newChannel) {
                    confirmDialog().then(function() {
                        sendChannel();
                    }, function() {});
                } else {
                    sendChannel();
                }
            }
        }
    };


    //Eurates module
    var getEuro = function() {
        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/euro/get",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: {},
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };

    var loadEuro = function() {
        getEuro().then(function(res) {
            $scope.euroList = res;
        });
    };
    loadEuro();

    var saveEuro = function(country_code, currency_name, eurrate) {

        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/euro/set",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: {},
            data: $.param({
                'sso': S.SSO(),
                'country_code': country_code,
                'currency_name': currency_name,
                'eurrate': eurrate
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.status);
                deferred.reject('Status: error');
            }
        }, function(msg, code) {
            deferred.reject(msg);
            $log.error(msg.status, code);
        });
        return deferred.promise;
    };

    $scope.saveEuro = function(country_code, currency_name, eurrate, error) {
        console.log(error);
        if (!error) {
            saveEuro(country_code, currency_name, eurrate).then(function() {
                loadEuro();
            });

        }
    };

    //Colorpicker required validation
    var colorValidator = function() {
        $scope.colorValidation = {};
        if ($scope.channelForm.color && $scope.channelForm.color !== "") {
            $scope.colorValidation.required = false;
        } else {
            $scope.colorValidation.required = true;
        }
    };

    $scope.toNumber = function(num) {
        return Number(num);
    };


    //
    //watchers
    //

    $scope.$watch('channel.color', function(newvalue, oldvalue) {
        colorValidator();
    });

    //channels country list
    $scope.$watch('country', function(newValues, oldValue) {
        if (newValues !== null) {
            loadChannels(newValues);
        }
    });

}]);
app.controller('contactsController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("contacts", "salesAndRep");
    $scope.checkPermission = $Services.checkPermission(permission.contacts);
    $scope.openContactAdd = function () {
        $state.go("contactAdd");
    }
}]);
app.controller('mapController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("map", "salesAndRep");
}]);
app.controller('photosController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("photos", "salesAndRep");
}]);
app.controller('ratingsFormEditController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("ratingsFormEdit", "salesAndRep");
}]);
app.controller('routeplanController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("routeplan", "salesAndRep");
}]);
app.controller('statsController', ['$scope', '$log', '$timeout', 'moment', '$statsRequest', function($scope, $log, $timeout, moment, $statsRequest) {
    /******************************************
     * Store visits and working hours (card1) *
     ******************************************/
    $scope.card1 = {};
    $scope.card1.startDate = moment().subtract(1, 'M').startOf('month').toDate();
    $scope.card1.endDate = moment().subtract(1, 'M').endOf('month').toDate();
    $scope.card1.worktime = true;
    $scope.card1.storevisit = true;
    $scope.card1.user = {};
    $scope.card1.maxDate1 = new Date();
    $scope.card1.maxDate2 = new Date();

    $scope.visitsAndWorkingHoursProgress = false; //progress

    $scope.card1.minDate1 = new Date(2016, 0, 1); //Start date min value

    $scope.$watch("card1.startDate", function(newVal) {
        //ha a start date és az end date közt több mint 4 hónap van, akkor legyen 4 hónapra maximalizálva
        var start = moment($scope.card1.startDate);
        var end = moment($scope.card1.endDate);

        //A maximum dátumót előbb kell definiálni mint a dátumot
        if (moment().isSameOrAfter(start.clone().add(4, 'M'), 'day')) {
            $scope.card1.maxDate2 = start.clone().add(4, 'M').toDate();
        } else {
            $scope.card1.maxDate2 = moment().toDate();
        }

        if (end.diff(start, 'months') > 4) {
            var endDate = start.clone();
            endDate.add(4, 'M');
            $scope.card1.endDate = null;
            $timeout(function() {
                $scope.card1.endDate = endDate.toDate();
            });
            $scope.card1.endDate = endDate.toDate();
        } else {

            if (start.isSameOrAfter(end)) {
                $scope.card1.endDate = start.toDate();
            }
        }

    });

    $scope.setOneDay = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(1, 'd').toDate();
    };

    $scope.setTwoDays = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(2, 'd').toDate();
    };

    $scope.setOneWeek = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(1, 'w').toDate();
    };

    $scope.setOneMonth = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(1, 'M').toDate();
    };

    $scope.exportVisitsAndWorkingHours = function() {
        $scope.visitsAndWorkingHoursProgress = true;
        $statsRequest.exportVisitsAndWorkingHours($scope.card1.country, $scope.card1.worktime, $scope.card1.storevisit, $scope.card1.startDate, $scope.card1.endDate, $scope.card1.user).then(function() {
            $scope.visitsAndWorkingHoursProgress = false;
        });
    };


    /******************************************
     * Last store ratings (card2)             *
     ******************************************/
    $scope.card2 = {};
    $scope.visitsLastStoreRatings = false; //progress

    $scope.exportLastStoreRatings = function() {
        $scope.visitsLastStoreRatings = true;
        $statsRequest.exportLastStoreRatings($scope.card2.country).then(function() {
            $scope.visitsLastStoreRatings = false;
        });
    };
}]);

app.controller("statsCard3Controller", ['$scope', 'moment', '$statsRequest', function($scope, moment, $statsRequest) {
    /**********************************************
     * Number of photos and store ratings (card3) *
     **********************************************/
    var actualMonth = moment().month();
    var actualYear = moment().year();
    $scope.year = moment().subtract(1, 'month').year();
    $scope.month = moment().subtract(1, 'month').month();
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.visitsNumberOfPhotosAndStoreRatings = false; //progress

    $scope.setMonth = function() {
        if (Number($scope.year) === actualYear) {
            if ($scope.month > actualMonth) {
                $scope.month = actualMonth;
            }
            $scope.yearLimit = actualMonth + 1;
        } else {
            $scope.yearLimit = 12;
        }
        console.log('month', $scope.month);
    };

    $scope.exportNumberOfPhotosAndStoreRatings = function() {
        $scope.visitsNumberOfPhotosAndStoreRatings = true;
        $statsRequest.exportNumberOfPhotosAndStoreRatings($scope.country, $scope.year, Number($scope.month) + 1).then(function() {
            $scope.visitsNumberOfPhotosAndStoreRatings = false;
        });
    };
}]);


app.controller("statsCard4Controller", ['$scope', 'moment', '$statsRequest', function($scope, moment, $statsRequest) {
    /**********************************************
     * Sob_qty (card4) *
     **********************************************/
    var actualMonth = moment().month();
    var actualYear = moment().year();
    $scope.year = moment().subtract(1, 'month').year();
    $scope.month = moment().subtract(1, 'month').month();
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.visitsSob_amt = false; //progress

    $scope.setMonth = function() {
        if (Number($scope.year) === actualYear) {
            if ($scope.month > actualMonth) {
                $scope.month = actualMonth;
            }
            $scope.yearLimit = actualMonth + 1;
        } else {
            $scope.yearLimit = 12;
        }
    };

    $scope.exportSob_amt = function() {
        $scope.visitsSob_amt = true;
        $statsRequest.exportSob_amt($scope.country, $scope.year, Number($scope.month) + 1).then(function() {
            $scope.visitsSob_amt = false;
        });
    };
}]);

app.controller("statsCard5Controller", ['$scope', 'moment', '$statsRequest', function($scope, moment, $statsRequest) {
    /**********************************************
     * Sob_qty (card5) *
     **********************************************/
    var actualMonth = moment().month();
    var actualYear = moment().year();
    $scope.year = moment().subtract(1, 'month').year();
    $scope.month = moment().subtract(1, 'month').month();
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.visitsSob_qty = false; //progress

    $scope.setMonth = function() {
        if (Number($scope.year) === actualYear) {
            if ($scope.month > actualMonth) {
                $scope.month = actualMonth;
            }
            $scope.yearLimit = actualMonth + 1;
        } else {
            $scope.yearLimit = 12;
        }
    };

    $scope.exportSob_qty = function() {
        $scope.visitsSob_qty = true;
        $statsRequest.exportSob_qty($scope.country, $scope.year, Number($scope.month) + 1).then(function() {
            $scope.visitsSob_qty = false;
        });
    };
}]);
app.controller('storesController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("stores", "salesAndRep");


    $scope.checkPermission = $Services.checkPermission(permission.stores_add);
    $scope.openStoreAdd = function () {
        $state.go("storeAdd");
    }
}]);
app.controller('todoController', ['$scope', '$state', '$Services', '$log', function($scope, $state, $Services, $log){
    M.loadContent("task_and_issues", "salesAndRep");
}]);
app.controller('userController', ['$scope', '$log', '$stateParams', '$userRequest', function($scope, $log, $stateParams, $userRequest){
    $log.log('userController');
    $userRequest.getUser($stateParams.id).then(function(response) {
        console.log(response);
        $scope.userData = response;
    });
}]);
app.service('$assignmentsRequest', ['$http', '$request', '$q','$log', '$window', 'FileSaver', 'Blob', 'Upload',function($http, $request, $q, $log, $window, FileSaver, Blob, Upload){
    this.scheme = function(params) {
        var deferred = $q.defer();

        $log.debug("Get assignments scheme list", params);
        $request.getRequest("/api-v2/promoters/assignments/scheme", params)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };
    this.getScheme = function(params) {
        var deferred = $q.defer();

        $log.debug("Get assignment scheme", params);
        $request.getRequest("/api-v2/promoters/assignments/scheme/" + params )
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };
    this.setScheme = function(params) {
        var deferred = $q.defer();

        $log.debug("Set assignment scheme ", params);
        $request.postRequest("/api-v2/promoters/assignments/scheme", params)
            .then(function(data) {
                if (data.result == "OK") {
                    deferred.resolve(data);
                }
            });
        return deferred.promise;
    };

    //Times
    this.times = function(params) {
        var deferred = $q.defer();

        $log.debug("Get assignments times list", params);
        $request.getRequest("/api-v2/promoters/assignments/times", params)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    //Statements
    this.statements = function(id, params) {
        var deferred = $q.defer();

        $log.debug("Join to feedback", params);
        $request.postRequest("/api-v2/promoters/assignments/statements/" + id, params)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    this.assignmentDelete = function(id) {
        var deferred = $q.defer();

        $log.debug("Delete to feedback", id);
        $request.deleteRequest("/api-v2/promoters/assignments/times/" + id)
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    this.assignmentEdit = function(id) {
        var deferred = $q.defer();

        $log.debug("Edit feedbackk", id);
        //$request.putRequest("/api-v2/promoters/assignments/times/" + id)
        $request.getRequest("/api-v2/test-confirm", {})
            .then(function(data) {
                deferred.resolve(data.content);
            });
        return deferred.promise;
    };

    this.reloadFeedbackList = false;

}]);
app.service('$loginRequest', ['$http', '$request_v1', '$q', '$log', function($http, $request_v1, $q, $log) {
    this.login = function(params) {
        var deferred = $q.defer();
        $request_v1.postRequest("api/login", params)
            .then(function(data) {
                deferred.resolve(data.result);
            }, function (error) {
                deferred.reject(error);
			});
        return deferred.promise;
    };
    this.activatetoken = function(params) {
        var deferred = $q.defer();
        $request_v1.postRequest("/api/userpwreset/trytoken", params)
            .then(function(data) {
                deferred.resolve(data.result);
            });
        return deferred.promise;
    };
    this.forgotpassword = function(params) {
        var deferred = $q.defer();
        $request_v1.postRequest("/api/userpwreset/gettoken", params)
            .then(function(data) {
                deferred.resolve(data.result);
            });
        return deferred.promise;
    };
}]);
app.service('$logoutRequest', ['$http', '$request_v1', '$q', '$log', function($http, $request_v1, $q, $log) {
    this.logout = function() {
        var deferred = $q.defer();
        $request_v1.postRequest("api/logout", {'sso': S.SSO()})
            .then(function(data) {
                deferred.resolve(data.result);
            }, function (error) {
                deferred.reject(error);
			});

        return deferred.promise;
    };
}]);
app.service('$payrollRequest', ['$http', '$request', '$q', '$log', '$window', 'FileSaver', 'Blob', 'Upload', function($http, $request, $q, $log, $window, FileSaver, Blob, Upload) {
    this.getTemplate = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        var country_code = pCountry_code || "HU";
        $log.debug("Get payroll template(", pCountry_code, year, month, ")");

        //Így lehetőség van arra, hogy nevet adjunk a filenak (enélkül generált neve lenne)
        var saveFile = function(text, fileName) {
            var data = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(data, fileName);
        };

        $request.fileDownloadRequest("/api-v2/promoters/payroll/template/" + year + "/" + month, { country_code: country_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };

    this.importXLS = function(file) {
        var deferred = $q.defer();
        $log.debug("Import payroll from file ", file.name);

        var fd = new FormData();
        fd.append('payroll', file);
        $request.fileUploadRequest("/api-v2/promoters/payroll/import", fd)
            .then(function() {
                deferred.resolve();
            });
        return deferred.promise;
    };

    this.addPayroll = function(params) {
        var deferred = $q.defer();
        $log.debug("Add payroll ", params);
        $request.postRequest("/api-v2/promoters/payroll", params)
            .then(function() {
                deferred.resolve();
            });
        return deferred.promise;
    };

    this.getPayroll = function(params) {
        var deferred = $q.defer();
        $log.debug("Get payroll ", params);
        $request.postRequest("/api-v2/promoters/payroll", params)
            .then(function() {
                deferred.resolve();
            });
        return deferred.promise;
    };
}]);
app.service('$statsRequest', ['$http', '$request', '$q', '$log', 'FileSaver', 'Blob', function($http, $request, $q, $log, FileSaver, Blob) {
    var saveFile = function(text, fileName) {
        var data = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(data, fileName);
    };

    /**
     * Download Store visits and working hours
     * @param country_code
     * @param want_worktime
     * @param want_storevisit
     * @param start_date
     * @param end_date
     * @param user
     * @return {*}
     */
    this.exportVisitsAndWorkingHours = function(country_code, want_worktime, want_storevisit, start_date, end_date, user) {
        var deferred = $q.defer();
        if (country_code == "0") {
            country_code = null;
        }
        $http({
            method: "post",
            url: "/api/stats",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'country_code': country_code,
                'userid': user.users__id,
                'want_worktime': want_worktime,
                'want_storevisit': want_storevisit,
                'start_date': start_date,
                'end_date': end_date,
                'want_xls': 1,
                'sso': S.SSO()
            }),
            responseType: 'arraybuffer'
        }).then(function(response) {
            var data = response.data;
            var fileParams = {
                content: data,
                name: response.headers('Content-Disposition').split('filename="')[1].split('"')[0]
            };
            saveFile(fileParams.content, fileParams.name);
            deferred.resolve();
        }, function(error) {
            console.log(error);
        });
        return deferred.promise;
    };

    /**
     * Download Last store ratings
     * @param country_code
     * @returns {*}
     */
    this.exportLastStoreRatings = function(country_code) {
        var deferred = $q.defer();
        if (country_code == "0") {
            country_code = null;
        }
        $http({
            method: "post",
            url: "/api/stats_storeratings",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'country_code': country_code,
                'want_xls': 1,
                'sso': S.SSO()
            }),
            responseType: 'arraybuffer'
        }).then(function(response) {
            var data = response.data;
            var fileParams = {
                content: data,
                name: response.headers('Content-Disposition').split('filename="')[1].split('"')[0]
            };
            saveFile(fileParams.content, fileParams.name);
            deferred.resolve();
        });
        return deferred.promise;
    };

    /**
     * Download Number of photos and store ratings
     * @param pCountry_code
     * @param year
     * @param month
     * @returns {*}
     */
    this.exportNumberOfPhotosAndStoreRatings = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        if (pCountry_code == "0") {
            pCountry_code = null;
        }
        $log.debug("Get Number of photos and store ratings(", pCountry_code, year, month, ")");

        $request.fileDownloadRequest("/api-v2/mkey/stats/userstats/" + year + "/" + month, { country_code: pCountry_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save as: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };

    /**
     * Download
     * @param pCountry_code
     * @param year
     * @param month
     * @returns {*}
     */
    this.exportSob_qty = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        if (pCountry_code == "0") {
            pCountry_code = null;
        }
        $log.debug("Get Sob_qty(", pCountry_code, year, month, ")");

        $request.fileDownloadRequest("/api-v2/mkey/stats/sob/qty/" + year + "/" + month, { country_code: pCountry_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save as: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };

    /**
     * Download
     * @param pCountry_code
     * @param year
     * @param month
     * @returns {*}
     */
    this.exportSob_amt = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        if (pCountry_code == "0") {
            pCountry_code = null;
        }
        $log.debug("Get sob_amt(", pCountry_code, year, month, ")");

        $request.fileDownloadRequest("/api-v2/mkey/stats/sob/amt/" + year + "/" + month, { country_code: pCountry_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save as: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };
}]);
app.service('$userRequest', ['$http', '$request_v1', '$q', '$log', function($http, $request_v1, $q, $log) {
    this.getUser = function (id) {
        var deferred = $q.defer();
        $request_v1.postRequest("/api/userlist?user_id=" + id, {'sso': S.SSO()})
            .then(function(data) {
                deferred.resolve(data.result);
            });
        return deferred.promise;
    }
}]);
/*
 * @param filter: szűri a listát ország alapján
 * @example
 * <div channel ng-model="channel"></div>
 */
app.directive('channel', ['$log', '$Services', function($log, $Services) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/channel/channel.html',
        scope: {
            ngModel: '=',
            filter: '=',
            disabled: '=ngDisabled'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            var channelList = function(country) {
                var channels = $Services.getChannelByCountry(country);
                channels.then(function(res) {
                    scope.channels = res;
                });
            }

            scope.$watch('filter', function(newValue, oldValue) {
                if (newValue !== undefined) {
                    channelList(scope.filter);
                } else {
                    $log.error("Missing filter parameter");
                }
            });

            var stopModel = scope.$watch('ngModel', function(newVal) {
                if (newVal) {
                    scope.channel = scope.ngModel;
                }
                stopModel();
            });

            scope.$watch('channel', function(newVal) {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(newVal);
            });
        }
    };
}]);
/*
 * @example
 * <div country ng-model="country"></div>
 */
app.directive('country', ['$User', function($User) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/country/country.html',
        scope: {
            ngModel: "=",
            showAll: "="
        },
        link: function(scope, element, attrs, ngModelCtrl) {

            var user = $User.getLoggedInUser();
            user.then(function(res) {
                scope.userCountries = $User.getCountries(res);
                if (scope.showAll) scope.userCountries.push({ name: "All", value: 0 });

                scope.country = res.users_country_code;
            });

            var stopModel = scope.$watch('ngModel', function(newVal) {
                if (newVal) {
                    scope.country = scope.ngModel;
                }
                stopModel();
            });

            scope.$watch('country', function(newVal, oldVal) {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(newVal);
            });

        }
    };
}]);
/*
 * @param filter szűri a listát ország alapján
 * @param rank jogosultsági szintre szűr. Vesszővel több szintre is lehet szűrni (root,admin,promoter,kam)
 * @example
 * <div user ng-model="user"></div>
 */
app.directive('daytime', ['$log', '$User', '$Services', function($log, $User, $Services) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/daytime/daytime.html',
        scope: {
            daytime: '=ngModel',
            array: '=',
            last: '=',
            index: '=',
            form: '='
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            var user = $User.getUser(S.USER()._id);

            //TODO: Meg kell nézni miért használom itt az usert
            user.then(function(res) {
                scope.days = $Services.getDays();
            });

            scope.addDayTime = function() {
                scope.array.push({ day: "", time: "" });
            };

            scope.removeDayTime = function() {
                scope.array.splice(scope.index, 1);
            };

            scope.$watch('daytime', function() {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(scope.daytime);
            });

            scope.$watch('directive.$valid', function(newValue, oldValue) {
                ngModelCtrl.$setValidity('valid', newValue);
            });
        }
    };
}]);
/*
 * @example
 * <div country ng-model="store"></div>
 */
app.directive('store', ['$User', '$Stores', function($User, $Stores) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/store/store.html',
        scope: {
            ngModel: "=",
            buCode: "=",
            countryCode: "=",
            channelId: "=",
            disabled:"=?",
            required:"="
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            if (scope.disabled === undefined) {
             scope.disabled = false;
            }
            scope.$watch('countryCode', function() {
                $Stores.getStores(scope.buCode, scope.countryCode, scope.channelId)
                    .then(function(res) {
                        scope.stores = res;
                    });
            });

            var stopModel = scope.$watch('ngModel', function(newVal) {
                if (newVal) {
                    scope.store = scope.ngModel;
                }
                stopModel();
            });

            scope.$watch('store', function(newVal) {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(newVal);
            });
        }
    };
}]);
app.directive('storeElement', ['$log', function($log) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/store_element/store_element.html',
        scope: {
            store: '=ngModel'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            // TODO: ha meglesz csinálva a store adatlap, törölni kell a commentet

            element.bind("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                Store.open(scope.store._id);
            })
        }
    };
}]);
/*
 * @param filter szűri a listát ország alapján
 * @param rank jogosultsági szintre szűr. Vesszővel több szintre is lehet szűrni (root,admin,promoter,kam)
 * @example
 * <div user ng-model="user"></div>
 */
app.directive('user', ['$log', '$User', function($log, $User) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/user/user.html',
        scope: {
            ngModel: '=',
            filter: '=',
            disabled: '=ngDisabled',
            rank: '@'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$setViewValue({});
            var user = $User.getAll();
            scope.selectedUser = [];
            scope.required = !!element.attr('required');

            var userFilter = function(country) {
                scope.users = [];
                scope.rank = scope.rank || '';
                var ranks = scope.rank.split(',');
                user.then(function(res) {
                    angular.forEach(res, function(users, userCountry) {
                        if (country !== undefined) {
                            if (country == userCountry) {
                                users.map(function(user) {
                                    if (scope.rank !== '') {
                                        for (var i = 0; i < ranks.length; i++) {
                                            var role = ranks[i].charAt(0).toUpperCase() + ranks[i].slice(1) + 'Role';
                                            if (role == user.users_rank) {
                                                scope.users.push(user);
                                            }
                                        }
                                    } else {
                                        scope.users.push(user);
                                    }
                                });
                            }
                        } else {
                            users.map(function(user) {
                                scope.users.push(user);
                            });
                        }
                    });
                    $log.debug(scope.users.length + " user added to list");
                });
            };

            scope.$watch('filter', function(newValue, oldValue) {
                $log.debug("User autocomplete list up dated", newValue);
                if (newValue !== undefined) {
                    userFilter(scope.filter);
                } else {
                    $log.error("Invalid filter parameter");
                }
            });

            scope.setUser = function(user) {
                $log.debug("Set user id to parent scope: ", user.users__id);
                ngModelCtrl.$setViewValue(user);
            };
        }
    };
}]);