app.controller('statsController', ['$scope', '$log', '$timeout', 'moment', '$statsRequest', function($scope, $log, $timeout, moment, $statsRequest) {
    /******************************************
     * Store visits and working hours (card1) *
     ******************************************/
    $scope.card1 = {};
    $scope.card1.startDate = moment().subtract(1, 'M').startOf('month').toDate();
    $scope.card1.endDate = moment().subtract(1, 'M').endOf('month').toDate();
    $scope.card1.worktime = true;
    $scope.card1.storevisit = true;
    $scope.card1.user = {};
    $scope.card1.maxDate1 = new Date();
    $scope.card1.maxDate2 = new Date();

    $scope.visitsAndWorkingHoursProgress = false; //progress

    $scope.card1.minDate1 = new Date(2016, 0, 1); //Start date min value

    $scope.$watch("card1.startDate", function(newVal) {
        //ha a start date és az end date közt több mint 4 hónap van, akkor legyen 4 hónapra maximalizálva
        var start = moment($scope.card1.startDate);
        var end = moment($scope.card1.endDate);

        //A maximum dátumót előbb kell definiálni mint a dátumot
        if (moment().isSameOrAfter(start.clone().add(4, 'M'), 'day')) {
            $scope.card1.maxDate2 = start.clone().add(4, 'M').toDate();
        } else {
            $scope.card1.maxDate2 = moment().toDate();
        }

        if (end.diff(start, 'months') > 4) {
            var endDate = start.clone();
            endDate.add(4, 'M');
            $scope.card1.endDate = null;
            $timeout(function() {
                $scope.card1.endDate = endDate.toDate();
            });
            $scope.card1.endDate = endDate.toDate();
        } else {

            if (start.isSameOrAfter(end)) {
                $scope.card1.endDate = start.toDate();
            }
        }

    });

    $scope.setOneDay = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(1, 'd').toDate();
    };

    $scope.setTwoDays = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(2, 'd').toDate();
    };

    $scope.setOneWeek = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(1, 'w').toDate();
    };

    $scope.setOneMonth = function() {
        $scope.card1.endDate = moment($scope.card1.startDate).add(1, 'M').toDate();
    };

    $scope.exportVisitsAndWorkingHours = function() {
        $scope.visitsAndWorkingHoursProgress = true;
        $statsRequest.exportVisitsAndWorkingHours($scope.card1.country, $scope.card1.worktime, $scope.card1.storevisit, $scope.card1.startDate, $scope.card1.endDate, $scope.card1.user).then(function() {
            $scope.visitsAndWorkingHoursProgress = false;
        });
    };


    /******************************************
     * Last store ratings (card2)             *
     ******************************************/
    $scope.card2 = {};
    $scope.visitsLastStoreRatings = false; //progress

    $scope.exportLastStoreRatings = function() {
        $scope.visitsLastStoreRatings = true;
        $statsRequest.exportLastStoreRatings($scope.card2.country).then(function() {
            $scope.visitsLastStoreRatings = false;
        });
    };
}]);

app.controller("statsCard3Controller", ['$scope', 'moment', '$statsRequest', function($scope, moment, $statsRequest) {
    /**********************************************
     * Number of photos and store ratings (card3) *
     **********************************************/
    var actualMonth = moment().month();
    var actualYear = moment().year();
    $scope.year = moment().subtract(1, 'month').year();
    $scope.month = moment().subtract(1, 'month').month();
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.visitsNumberOfPhotosAndStoreRatings = false; //progress

    $scope.setMonth = function() {
        if (Number($scope.year) === actualYear) {
            if ($scope.month > actualMonth) {
                $scope.month = actualMonth;
            }
            $scope.yearLimit = actualMonth + 1;
        } else {
            $scope.yearLimit = 12;
        }
        console.log('month', $scope.month);
    };

    $scope.exportNumberOfPhotosAndStoreRatings = function() {
        $scope.visitsNumberOfPhotosAndStoreRatings = true;
        $statsRequest.exportNumberOfPhotosAndStoreRatings($scope.country, $scope.year, Number($scope.month) + 1).then(function() {
            $scope.visitsNumberOfPhotosAndStoreRatings = false;
        });
    };
}]);


app.controller("statsCard4Controller", ['$scope', 'moment', '$statsRequest', function($scope, moment, $statsRequest) {
    /**********************************************
     * Sob_qty (card4) *
     **********************************************/
    var actualMonth = moment().month();
    var actualYear = moment().year();
    $scope.year = moment().subtract(1, 'month').year();
    $scope.month = moment().subtract(1, 'month').month();
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.visitsSob_amt = false; //progress

    $scope.setMonth = function() {
        if (Number($scope.year) === actualYear) {
            if ($scope.month > actualMonth) {
                $scope.month = actualMonth;
            }
            $scope.yearLimit = actualMonth + 1;
        } else {
            $scope.yearLimit = 12;
        }
    };

    $scope.exportSob_amt = function() {
        $scope.visitsSob_amt = true;
        $statsRequest.exportSob_amt($scope.country, $scope.year, Number($scope.month) + 1).then(function() {
            $scope.visitsSob_amt = false;
        });
    };
}]);

app.controller("statsCard5Controller", ['$scope', 'moment', '$statsRequest', function($scope, moment, $statsRequest) {
    /**********************************************
     * Sob_qty (card5) *
     **********************************************/
    var actualMonth = moment().month();
    var actualYear = moment().year();
    $scope.year = moment().subtract(1, 'month').year();
    $scope.month = moment().subtract(1, 'month').month();
    $scope.years = CONFIG.YEARS;
    $scope.months = CONFIG.MONTH_NAMES;

    $scope.visitsSob_qty = false; //progress

    $scope.setMonth = function() {
        if (Number($scope.year) === actualYear) {
            if ($scope.month > actualMonth) {
                $scope.month = actualMonth;
            }
            $scope.yearLimit = actualMonth + 1;
        } else {
            $scope.yearLimit = 12;
        }
    };

    $scope.exportSob_qty = function() {
        $scope.visitsSob_qty = true;
        $statsRequest.exportSob_qty($scope.country, $scope.year, Number($scope.month) + 1).then(function() {
            $scope.visitsSob_qty = false;
        });
    };
}]);