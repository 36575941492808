/**
 * @param bu_code
 * @param country_code
 * @param channel_id
 */
app.service("$Stores", ['$http', '$q', function($http, $q) {
    var deferred;
    this.getStores = function(bu_code, country_code, channel_id) {
        var params = {};

        if (country_code !== undefined && country_code !== 0) params.country_code = country_code;
        if (channel_id !== undefined && channel_id !== 0) params.channel_id = channel_id;
        if (bu_code !== undefined && bu_code !== 0) params.bu_code = bu_code;

        if (deferred) {
            deferred.resolve("Store call cancelled and recall");
        }
        deferred = $q.defer();
        $http({
            method: "post",
            url: "api/storelist",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: params,
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };

}]);