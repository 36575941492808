/*
 * @param filter szűri a listát ország alapján
 * @param rank jogosultsági szintre szűr. Vesszővel több szintre is lehet szűrni (root,admin,promoter,kam)
 * @example
 * <div user ng-model="user"></div>
 */
app.directive('user', ['$log', '$User', function($log, $User) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/user/user.html',
        scope: {
            ngModel: '=',
            filter: '=',
            disabled: '=ngDisabled',
            rank: '@'
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$setViewValue({});
            var user = $User.getAll();
            scope.selectedUser = [];
            scope.required = !!element.attr('required');

            var userFilter = function(country) {
                scope.users = [];
                scope.rank = scope.rank || '';
                var ranks = scope.rank.split(',');
                user.then(function(res) {
                    angular.forEach(res, function(users, userCountry) {
                        if (country !== undefined) {
                            if (country == userCountry) {
                                users.map(function(user) {
                                    if (scope.rank !== '') {
                                        for (var i = 0; i < ranks.length; i++) {
                                            var role = ranks[i].charAt(0).toUpperCase() + ranks[i].slice(1) + 'Role';
                                            if (role == user.users_rank) {
                                                scope.users.push(user);
                                            }
                                        }
                                    } else {
                                        scope.users.push(user);
                                    }
                                });
                            }
                        } else {
                            users.map(function(user) {
                                scope.users.push(user);
                            });
                        }
                    });
                    $log.debug(scope.users.length + " user added to list");
                });
            };

            scope.$watch('filter', function(newValue, oldValue) {
                $log.debug("User autocomplete list up dated", newValue);
                if (newValue !== undefined) {
                    userFilter(scope.filter);
                } else {
                    $log.error("Invalid filter parameter");
                }
            });

            scope.setUser = function(user) {
                $log.debug("Set user id to parent scope: ", user.users__id);
                ngModelCtrl.$setViewValue(user);
            };
        }
    };
}]);