app.controller('assignmentsEditController', ['$scope', '$state', '$Services', '$assignmentsRequest', '$log', '$User', '$Stores', '$http', '$filter', '$mdDialog', 'moment', '$timeout', 'assignment', function($scope, $state, $Services, $assignmentsRequest, $log, $User, $Stores, $http, $filter, $mdDialog, moment, $timeout, assignment) {
    $log.debug("assignmentsEditController ", assignment);
    var user = $User.getUser(S.USER()._id);

    //Default form values
    $scope.selectedItem = null;
    $scope.searchText = null;
    $scope.progress = false;
    $scope.userCountries = null;
    $scope.userCountry = null;
    $scope.channels = null;
    $scope.assignment = assignment;
    $scope.daytimes = [];


    var load = function() {
        $assignmentsRequest.getScheme(assignment._id).then(function(data) {

            //$scope.assignment = data;

            $scope.minDate = new Date(data.start_date);
            $scope.assignment.startDate = new Date(data.start_date);
            $scope.assignment.endDate = new Date(data.end_date);
            $scope.assignment.created = new Date(data.date_created);
            $scope.assignment.modified = data.date_modified ? new Date(data.date_modified) : null;

            $scope.assignment.user_created = data.user_created;
            $scope.assignment.user_modified = data.user_modified ? data.user_modified : null;

            $scope.assignment.store = data.store;
            $scope.assignment.users = data.users;
            angular.forEach(data.days, function(value, key) {

                for (var dayParams in value) {
                    var param = {};

                    param.day = key;
                    param.startTime = moment(value[dayParams].start, 'HH:mm:ss+Z').toDate();
                    param.endTime = moment(param.startTime).add(value[dayParams].duration, 'm').toDate();

                    $scope.daytimes.push(param);
                }

                //console.log(value);

            });
        });
    };

    load();

    $scope.searchInJSON = function(array, keyName, key) {
        var returnValue = "";
        angular.forEach(array, function(value) {
            if (value[keyName] == key) {
                returnValue = value;
            }
        });
        return returnValue;
    };

    user.then(function(res) {
        $scope.userCountries = $User.getCountries(res);
        $scope.assignment.country = res.users_country_code;
        $scope.userBUs = $User.getBUs(res);
        $scope.days = $Services.getDays();
        $scope.assignment.bu = $scope.userBUs[0].value;
    });

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.sendForm = function() {
        $log.debug("sending...", $scope.assignment);

        if ($scope.assignmentForm.$valid) {
            $scope.progress = true;
            $scope.progressState = 0;
            $scope.progressIndex = 0;
            $scope.progressText = "";
            var progress = function(c, count) {
                var x = 100 / count;
                $scope.progressState += x;
                $scope.progressText = c + "/" + count;
                if ($scope.progressState === 100) $scope.progressText = "Complete";
            };

            var success = 0;
            $scope.assignment.selectedStores.map(function(store, index) {
                $scope.progress = true;
                var params = {
                    "store_id": store.stores__id,
                    "bu_code": $scope.assignment.bu,
                    "start_date": $filter('date')($scope.assignment.startDate, "yyyy-MM-dd"),
                    "end_date": $filter('date')($scope.assignment.endDate, "yyyy-MM-dd"),
                    "days": {}
                };

                for (var i in $scope.daytimes) {
                    var daytime = $scope.daytimes[i];
                    if (params.days[daytime.day] === undefined) {
                        params.days[daytime.day] = [];
                    }
                    var duration = moment.duration(moment(daytime.endTime).diff(daytime.startTime));
                    var hours = duration.asMinutes();
                    params.days[daytime.day].push({ 'start': $filter('date')(daytime.startTime, 'HH:mm:ss'), 'duration': hours });
                }

                $assignmentsRequest.setScheme(params).then(function() {
                    success++;
                    var _index = index + 1;
                    if (success == $scope.assignment.selectedStores.length) {
                        $timeout(function() { $mdDialog.hide(); }, 3000);
                    }
                    progress(_index, $scope.assignment.selectedStores.length);
                });
            });
        }


    };

    $scope.toggleButton = function(toggle, day) {
        $scope.assignment.weekday = $scope.assignment.weekday || [];
        if (toggle) {
            $scope.assignment.weekday.push(day);
        } else {
            for (var i in $scope.assignment.weekday) {
                if ($scope.assignment.weekday[i] == day) {
                    $scope.assignment.weekday.splice(i, 1);
                }
            }
        }
    };


    //watchers
    $scope.$watchGroup(['assignment.country', 'assignment.channel', 'assignment.bu'], function(newValues, oldValue) {
        if (newValues[0] !== undefined) {
            var channels = $Services.getChannelByCountry(newValues[0]);
            channels.then(function(res) {
                $scope.channels = res;
            });
        }
        if (newValues[0] !== null && newValues[1] !== null && newValues[2] !== null) {
            $Stores.getStores(newValues[2], newValues[0], newValues[1])
                .then(function(res) {
                    $scope.stores = res;
                });
        }
    });

    var storeValidator = function() {
        $scope.storeValidation = {};
        if ($scope.assignment.selectedStores === undefined || $scope.assignment.selectedStores.length === 0) {
            $scope.storeValidation.required = true;
        } else {
            $scope.storeValidation.required = false;
        }
    };


    $scope.$watch('assignment.selectedStores', function(newvalue, oldvalue) {
        storeValidator();
    }, true);

    var dayValidator = function() {
        $scope.dayValidation = {};
        if (($scope.assignment.weekday && $scope.assignment.weekday.length > 0) || $scope.assignment.allDay) {
            if ($scope.assignment.weekday !== undefined) console.log($scope.assignment.weekday.length);
            $scope.dayValidation.required = false;
        } else {
            $scope.dayValidation.required = true;
        }
    };
    $scope.$watch('assignment.weekday', function(newvalue, oldvalue) {
        dayValidator();
    }, true);
    $scope.$watch('assignment.allDay', function(newvalue, oldvalue) {
        dayValidator();
    });
}]);