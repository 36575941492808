app.controller('configsController', ['$scope', '$state', '$User', '$Services', 'Upload', '$mdDialog', '$http', '$q', '$log', function($scope, $state, $User, $Services, Upload, $mdDialog, $http, $q, $log) {
    var user = $User.getUser(S.USER()._id);


    $scope.userCountries = null;
    $scope.channels = null;
    $scope.country = null;
    $scope.newChannel = false;
    $scope.monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    $scope.month = new Date().getMonth() + 1;
    $scope.year = new Date().getFullYear();
    $scope.yearList = $Services.yearList();
    $scope.channel = {};
    $scope.countryTranslator = CONFIG.COUNTRIES;
    user.then(function(res) {
        $scope.userCountries = $User.getCountries(res);
        $scope.country = res.users_country_code;
    });

    //Channels module

    var loadChannels = function(country) {
        var channels = $Services.getChannelByCountry(country);
        channels.then(function(res) {
            $scope.channels = res;
        });
    };

    $scope.showChannel = function(_channel) {
        $scope.newChannel = false;
        _channel = _channel || {};
        $scope.channel.id = _channel._id || '';
        $scope.channel.name = _channel.name || '';
        $scope.channel.color = _channel.color || '#621A4A';
        $scope.channel.parent = _channel.parent || 0;
        $scope.channel.country = _channel.country_code || $scope.country;
        $scope.channel.store_visits_in_year = Number(_channel.store_visits_in_year) || 1;
        $scope.channel.default_visit_time = Number(_channel.default_visit_time) || 1;
    };

    $scope.cancelImage = function() {
        delete $scope.channel.icon;
    };

    $scope.createNewChannel = function() {
        $scope.showChannel();
        $scope.newChannel = true;
    };


    var confirmDialog = function() {
        var confirm = $mdDialog.confirm()
            .title('You not selected icon to channel! Dou you want to use the default icon?')
            //.textContent('You not selected icon to channel! Dou you want to use the default icon?')
            .ok('Yes')
            .cancel('No');
        return $mdDialog.show(confirm);
    };

    var sendChannel = function() {
        var params = $scope.channel;
        params.sso = S.SSO();
        Upload.upload({
            url: '/api/addeditchannel',
            data: $scope.channel
        }).then(function(response) {
            var resp = response.data;
            loadChannels($scope.country);
            $Services.toastMessage("Channel added");
            $log.debug(resp);
        }, function(response) {
            var resp = response.data;
            $Services.toastMessage(resp.status, 'error');
            $log.error(resp);
        });
    };

    $scope.saveChannel = function() {
        if ($scope.channelForm.$valid) {
            $scope.channel.use_default_icon = true;
            if ($scope.channel.file !== undefined) {
                $scope.channel.use_default_icon = false;
                sendChannel();
            } else {
                if ($scope.newChannel) {
                    confirmDialog().then(function() {
                        sendChannel();
                    }, function() {});
                } else {
                    sendChannel();
                }
            }
        }
    };


    //Eurates module
    var getEuro = function() {
        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/euro/get",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: {},
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(msg, code) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };

    var loadEuro = function() {
        getEuro().then(function(res) {
            $scope.euroList = res;
        });
    };
    loadEuro();

    var saveEuro = function(country_code, currency_name, eurrate) {

        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/euro/set",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: {},
            data: $.param({
                'sso': S.SSO(),
                'country_code': country_code,
                'currency_name': currency_name,
                'eurrate': eurrate
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.status);
                deferred.reject('Status: error');
            }
        }, function(msg, code) {
            deferred.reject(msg);
            $log.error(msg.status, code);
        });
        return deferred.promise;
    };

    $scope.saveEuro = function(country_code, currency_name, eurrate, error) {
        console.log(error);
        if (!error) {
            saveEuro(country_code, currency_name, eurrate).then(function() {
                loadEuro();
            });

        }
    };

    //Colorpicker required validation
    var colorValidator = function() {
        $scope.colorValidation = {};
        if ($scope.channelForm.color && $scope.channelForm.color !== "") {
            $scope.colorValidation.required = false;
        } else {
            $scope.colorValidation.required = true;
        }
    };

    $scope.toNumber = function(num) {
        return Number(num);
    };


    //
    //watchers
    //

    $scope.$watch('channel.color', function(newvalue, oldvalue) {
        colorValidator();
    });

    //channels country list
    $scope.$watch('country', function(newValues, oldValue) {
        if (newValues !== null) {
            loadChannels(newValues);
        }
    });

}]);