/*
 * Main page controller
 * Ezekhez az oldalakhoz kell login
 */
app.controller('mainController', ['$scope', '$rootScope', '$state', '$Services', '$User', '$log', '$logoutRequest', function($scope, $rootScope, $state, $Services, $User, $log, $logoutRequest){
    $log.info("Main loaded");
    $scope.userPhoto = "/frontend/ui/pics/user_fake.png";
    $scope.userPhotoMedium = "/frontend/ui/pics/user_fake.png";
	$scope.user = {};
    //Todo: át kell írni
    $rootScope.$watch("loggedInUserId", function(){
        var user = $Services.getUser();
        if(user != null){
            $scope.user = user;
            $scope.userName = user.name;
            $scope.userId = user._id;
            $scope.userEmail = user.email;
            $scope.userPhoto = "/api/userphoto/" + $scope.userId + "/small/circle";
            $scope.userPhotoMedium = "/api/userphoto/" + $scope.userId + "/medium/circle";

            $scope.logout = function(event) {
                delete $rootScope.loggedInUserId;
				$logoutRequest.logout();
                $Services.deleteUser();
				$state.go("login");
                return EVENT.PREVENT(event);
            };

            $scope.openUser = function(event, id) {
                User.open(event, id);
            };

            $scope.permission = permission;
            $scope.checkPermission = $Services.checkPermission;
            $scope.isAdmin = $Services.isLoggedInUserAdmin;

        } else {
            $state.go("login", { go: $state.current });
            $Services.showLogin();
        }
    });
}]);