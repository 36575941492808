app.filter("storeFilter", [function(){
    return function(input, value, array) {
        function hasMatch(s) {
            return s.toLowerCase().indexOf(value.toLowerCase())!==-1;
        }
        var matches = [];
        input.map(function(_input){
            var obj = _input;
            array.map(function(arr) {
                if (_input == arr) {
                    obj = null;
                }
            });
            if (obj !== null && (value === "" || value === null || hasMatch(obj.stores_name) || hasMatch(obj.stores_address) || hasMatch(obj.stores_city))) {
                matches.push(obj);
            }
        });
        return matches;
    };
}]);