app.factory('$User', ['$http', '$q', '$log', '$sessionStorage', function($http, $q, $log, $sessionStorage) {
    var that = {};
    that.getUser = function(id) {
        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/userlist",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            params: {
                'user_id': id
            },
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(error) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };
    that.getAll = function() {
        var deferred = $q.defer();
        $http({
            method: "post",
            url: "api/userlist",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'sso': S.SSO()
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                deferred.resolve(data.result);
            } else {
                $log.error(data.result.code + ": " + data.result.message);
                deferred.reject('Status: error');
            }
        }, function(error) {
            deferred.reject(msg);
            $log.error(msg, code);
        });
        return deferred.promise;
    };
    that.getLoggedInUser = function() {
        var deferred = $q.defer();
        var user = that.getUser(S.USER()._id);


        if ($sessionStorage.loggedInUser !== undefined) {
            $log.debug("Get logged in user from session");
            deferred.resolve($sessionStorage.loggedInUser);
        } else {
            $log.debug("Get logged in user from api");
            user.then(function(res) {
                $sessionStorage.loggedInUser = res;
                deferred.resolve(res);
            });
        }
        return deferred.promise;
    };

    that.isAdmin = function(user) {
        return (user.users_rank == "AdminRole") || (user.users_rank == "RootRole");
    };

    that.getCountries = function(user) {
        var that = this;
        var countriesList = user.assigned_countries.split(',');
        var userCountries = [];
        angular.forEach(CONFIG.COUNTRIES, function(k, v) {
            if (user.assigned_countries !== null && !that.isAdmin(user)) {
                angular.forEach(countriesList, function(l, x) {
                    if (l == v) {
                        userCountries.push({ name: k, value: v });
                    }
                });
            } else {
                userCountries.push({ name: k, value: v });
            }
        });
        return userCountries;
    };
    that.getBUs = function(user) {
        var that = this;
        var buList = user.bu_list.split(',');
        var userBUs = [];
        angular.forEach(CONFIG.BUSINESS_UNITS, function(k, v) {
            if (user.bu_list !== null && !that.isAdmin(user)) {
                angular.forEach(buList, function(l, x) {
                    if (x == k) {
                        userBUs.push({ name: k, value: v });
                    }
                });
            } else {
                userBUs.push({ name: k, value: v });
            }
        });
        return userBUs;
    };
    return that;
}]);