var app = angular.module('mKey', ['ui.router', 'ngAnimate', 'ngStorage', 'ngCookies', 'ngMdIcons', 'ngMaterial', 'ngMessages', 'ngFileUpload', 'mdColorPicker', 'ngFileSaver', 'angularMoment', 'md.data.table', 'angular-click-outside']);

app.config(["$stateProvider", "$urlRouterProvider", "$mdThemingProvider", "$mdDateLocaleProvider", "$locationProvider", "$qProvider", function ($stateProvider, $urlRouterProvider, $mdThemingProvider, $mdDateLocaleProvider, $locationProvider, $qProvider) {
    $locationProvider.html5Mode({
        enabled: false
    }).hashPrefix('');

    $urlRouterProvider.otherwise("");
    //------------------------
    //Login Required Pages
    //------------------------
    $stateProvider
        .state('main', {
            url: '',
            views: {
                "main": {
                    templateUrl: "/frontend/ui/template/main.html",
                    controller: "mainController"
                }
            }
        })
        .state('login', {
            url: '/login',
            params: {
                go: null
            },
            views: {
                "main": {
                    templateUrl: '/frontend/ui/template/login.html',
                    controller: "loginController"
                }
            }
        })
        .state("forgotPassword", {
            url: '/forgotpassword',
            views: {
                "main": {
                    templateUrl: "/frontend/ui/template/forgotPassword.html",
                    controller: "forgotPasswordController"
                }
            }
        })
        .state("activateToken", {
            url: '/activatetoken?token',
            views: {
                "main": {
                    templateUrl: "/frontend/ui/template/activateToken.html",
                    controller: "activateTokenController"
                }
            }
        })
        .state('user', {
            url: '/user/:id',
            parent: 'main',
            onEnter: function ($mdMedia, $mdDialog, $state) {
                var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
                $mdDialog.show({
                    controller: "userController",
                    templateUrl: "/frontend/ui/template/salesAndRep/user.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    onRemoving: function () {
                        $state.go("payroll");
                    },
                    fullscreen: useFullScreen
                });
            },

            onExit: function ($mdMedia, $mdDialog) {
                $mdDialog.hide();
            },
            views: {
                "dialog": {
                    data: {
                        permission: permission.user
                    }
                }
            }
        })
        .state('users', {
            url: '/users',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.users
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/users.html",
                    controller: "usersController"
                }
            }
        })
        .state(('userAdd'), {
            parent: "users",
            url: '/add',
            onEnter: function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("users", "salesAndRep");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("users");
                }
            },
            views: {
                "dialog": {
                    data: {
                        permission: permission.user_add
                    }
                }
            }
        })
        .state('contacts', {
            url: '/contacts',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.contacts
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/contacts.html",
                    controller: "contactsController"
                }
            }
        })
        .state(('contactAdd'), {
            parent: "contacts",
            url: '/add',
            onEnter: function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("contacts", "salesAndRep");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("contacts");
                }
            },
            views: {
                "dialog": {
                    data: {
                        permission: permission.contacts_add
                    }
                }
            }
        })
        .state('configs', {
            url: '/configs',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.configs
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/configs.html",
                    controller: "configsController"
                }
            }
        })
        .state('ratingsFormEdit', {
            url: '/ratingsFormEdit',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.ratingsFormEdit
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/ratingsFormEdit.html",
                    controller: "ratingsFormEditController"
                }
            }
        })
        .state('stats', {
            url: '/stats',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.stats
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/stats.html",
                    controller: "statsController"
                }
            }
        })
        .state('stores', {
            url: '/stores',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.stores
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/stores.html",
                    controller: "storesController"
                }
            }
        })
        .state('storeAdd', {
            url: '/add',
            parent: 'stores',
            onEnter: function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("stores", "salesAndRep");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("stores");
                }
            },
            views: {
                "dialog": {
                    data: {
                        permission: permission.stores_add
                    }
                }
            }
        })
        .state('map', {
            url: '/map',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.map
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/map.html",
                    controller: "mapController"
                }
            }
        })
        .state('routeplan', {
            url: '/routeplan',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.routeplan
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/routeplan.html",
                    controller: "routeplanController"
                }
            }
        })
        .state('photos', {
            url: '/photos',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.photos
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/photos.html",
                    controller: "photosController"
                }
            }
        })
        .state('todo', {
            url: '/todo?id',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.todo
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/task_and_issues.html",
                    controller: "todoController"
                }
            }
        })
        .state('apps', {
            url: '/apps',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.apps
                    },
                    templateUrl: "/frontend/ui/template/salesAndRep/apps.html",
                    controller: "appsController"
                }
            }
        })
        .state('meetings', {
            url: '/meetings',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.meetings
                    },
                    templateUrl: "/frontend/ui/template/promoters/meetings.html",
                    controller: "meetingsController"
                }
            }
        })
        .state('meetingsAdd', {
            url: '/add',
            parent: 'meetings',
            onEnter: function ($mdMedia, $mdDialog, $state) {
                M.loadContentToAdd("meetings", "promoters");
                $.magnificPopup.instance.close = function() {
                    $.magnificPopup.proto.close.call(this);
                    delete $.magnificPopup.instance.close;
                    $state.go("meetings");
                }
            },
            views: {
                "dialog": {
                    data: {
                        permission: permission.meetings_add
                    }
                }
            }
        })
        .state('assignments', {
            url: '/assignments',
            parent: 'main',
            views: {
                "content": {
                    data: {
                        permission: permission.assignments
                    },
                    templateUrl: "/frontend/ui/template/promoters/assignments.html",
                    controller: "assignmentsController"
                }
            }
        })

        .state('feedbacks', {
            url: '/feedbacks',
            parent: 'assignments',
            params: {
                reload: false,
                filters: {}
            },
            views: {
                "tabContent": {
                    data: {
                        permission: permission.feedbacks
                    },
                    templateUrl: "/frontend/ui/template/promoters/assignments_feedbacks.html",
                    controller: "assignmentsFeedbacksController"
                }
            }
        })
        .state("scheme", {
            parent: "assignments",
            url: '/scheme',
            views: {
                "tabContent": {
                    data: {
                        permission: permission.scheme
                    },
                    templateUrl: "/frontend/ui/template/promoters/assignments_scheme.html",
                    controller: "assignmentsSchemeController"
                }
            }
        })
        .state("viewTimes", {
            parent: "feedbacks",
            url: '/view/{id}',
            params: {
                time: null,
                filters: null
            },
            onEnter: function ($mdMedia, $mdDialog, $state, $stateParams) {
                var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
                $mdDialog.show({
                    controller: "assignmentsFeedbacksDialogController",
                    templateUrl: "/frontend/ui/template/promoters/assignments_feedback_dialog.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: useFullScreen,
                    locals: {
                        type: "View",
                        timeId: $stateParams.id,
                        time: $stateParams.time
                    }
                })
                    .then(function () {
                        //$state.transitionTo('feedbacks', {filters: $stateParams.filters}, {reload: true});
                    }, function () {
                        $state.transitionTo('feedbacks', {filters: $stateParams.filters});
                    });
            }
        })
        .state("payroll", {
            parent: "main",
            url: '/payroll',
            views: {
                "content": {
                    data: {
                        permission: permission.payroll
                    },
                    templateUrl: "/frontend/ui/template/promoters/payroll.html",
                    controller: "payrollController"
                }
            }
        })
        .state("payrollAdd", {
            parent: 'payroll',
            url: '/add',
            onEnter: function ($mdMedia, $mdDialog, $state) {
                var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'));
                $mdDialog.show({
                    controller: "payrollAddController",
                    templateUrl: "/frontend/ui/template/promoters/payroll_add.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    onRemoving: function () {
                        $state.go("payroll");
                    },
                    fullscreen: useFullScreen
                });
            },

            onExit: function ($mdMedia, $mdDialog) {
                $mdDialog.hide();
            },
            views: {
                "dialog": {
                    controller: "payrollAddController",
                    data: {
                        permission: permission.payroll_add
                    }
                }
            }
        });


    // $mdThemingProvider.disableTheming();

    $mdThemingProvider.theme('error-toast');

    var lgRed = $mdThemingProvider.extendPalette('red', {
        '50': '#b10143',
        '100': '#b10143',
        '200': '#b10143',
        '300': '#b10143',
        '400': '#b10143',
        '500': '#b10143',
        '600': '#b10143',
        '700': '#b10143',
        '800': '#b10143',
        '900': '#b10143',
        'contrastDefaultColor': 'light'
    });

// Register the new color palette map with the name <code>neonRed</code>
    $mdThemingProvider.definePalette('lgRed', lgRed);

// Use that theme for the primary intentions
    $mdThemingProvider.theme('default')
        .primaryPalette('lgRed')
        .accentPalette('lgRed');

//set datepicker format
    $mdDateLocaleProvider.formatDate = function (date) {
        return moment(date).format('YYYY-MM-DD');
    };
}])
;

app.run(['$rootScope', '$state', '$mdMedia', '$mdDialog', '$Services', function ($rootScope, $state, $mdMedia, $mdDialog, $Services) {
    $rootScope.$on('$stateChangeStart', function (event, to) {
        //Ha nincs joga megnézni az oldalt autómatikusan a főoldalra irányítjuk
        if (to.data && to.data.permission && !$Services.checkPermission(to.data.permission)) {
            event.preventDefault();
            $state.go('main');
        }
    });
}]);

/*
 * Other Pages Contoller
 */