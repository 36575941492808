/*
 * @example
 * <div country ng-model="country"></div>
 */
app.directive('country', ['$User', function($User) {
    return {
        require: 'ngModel',
        restrict: 'A',
        templateUrl: '/frontend/ui/template/country/country.html',
        scope: {
            ngModel: "=",
            showAll: "="
        },
        link: function(scope, element, attrs, ngModelCtrl) {

            var user = $User.getLoggedInUser();
            user.then(function(res) {
                scope.userCountries = $User.getCountries(res);
                if (scope.showAll) scope.userCountries.push({ name: "All", value: 0 });

                scope.country = res.users_country_code;
            });

            var stopModel = scope.$watch('ngModel', function(newVal) {
                if (newVal) {
                    scope.country = scope.ngModel;
                }
                stopModel();
            });

            scope.$watch('country', function(newVal, oldVal) {
                //modell értékének beállítása
                ngModelCtrl.$setViewValue(newVal);
            });

        }
    };
}]);