app.controller('forgotPasswordController', ['$scope', '$state', '$http', '$Services', '$log', '$loginRequest', function ($scope, $state, $http, $Services, $log, $loginRequest) {
    $log.info("forgotPassword...");

    $scope.getToken = function (e, email) {
        $log.debug("Call getToken service");
        //ToDo: felül kell vizsgálni, hogy erre szükség van e
        e.preventDefault();
        $http({
            method: "post",
            url: "/api/userpwreset/gettoken",
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            data: $.param({
                'email': email
            })
        }).then(function (response) {
            var data = response.data;
            if (S.OK(data)) {
                alertify.alert("Email sent");
                setTimeout(function () {
                    $state.go('main.mkey');
                }, 3000);
            }
        }, function (msg, code) {
            $log.error(msg, code);
        });
    };
    $scope.send = function () {
        $loginRequest.forgotpassword({'email': $scope.email}).then(function (response) {
            $Services.toastMessage("The password recovery e-mail has been sent. Please follow the instructions.");
            $state.go('activateToken');
        }, function (msg, code) {
            $log.error(msg, code);
        });
    };
}]);