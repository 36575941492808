app.service('$statsRequest', ['$http', '$request', '$q', '$log', 'FileSaver', 'Blob', function($http, $request, $q, $log, FileSaver, Blob) {
    var saveFile = function(text, fileName) {
        var data = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(data, fileName);
    };

    /**
     * Download Store visits and working hours
     * @param country_code
     * @param want_worktime
     * @param want_storevisit
     * @param start_date
     * @param end_date
     * @param user
     * @return {*}
     */
    this.exportVisitsAndWorkingHours = function(country_code, want_worktime, want_storevisit, start_date, end_date, user) {
        var deferred = $q.defer();
        if (country_code == "0") {
            country_code = null;
        }
        $http({
            method: "post",
            url: "/api/stats",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'country_code': country_code,
                'userid': user.users__id,
                'want_worktime': want_worktime,
                'want_storevisit': want_storevisit,
                'start_date': start_date,
                'end_date': end_date,
                'want_xls': 1,
                'sso': S.SSO()
            }),
            responseType: 'arraybuffer'
        }).then(function(response) {
            var data = response.data;
            var fileParams = {
                content: data,
                name: response.headers('Content-Disposition').split('filename="')[1].split('"')[0]
            };
            saveFile(fileParams.content, fileParams.name);
            deferred.resolve();
        }, function(error) {
            console.log(error);
        });
        return deferred.promise;
    };

    /**
     * Download Last store ratings
     * @param country_code
     * @returns {*}
     */
    this.exportLastStoreRatings = function(country_code) {
        var deferred = $q.defer();
        if (country_code == "0") {
            country_code = null;
        }
        $http({
            method: "post",
            url: "/api/stats_storeratings",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'country_code': country_code,
                'want_xls': 1,
                'sso': S.SSO()
            }),
            responseType: 'arraybuffer'
        }).then(function(response) {
            var data = response.data;
            var fileParams = {
                content: data,
                name: response.headers('Content-Disposition').split('filename="')[1].split('"')[0]
            };
            saveFile(fileParams.content, fileParams.name);
            deferred.resolve();
        });
        return deferred.promise;
    };

    /**
     * Download Number of photos and store ratings
     * @param pCountry_code
     * @param year
     * @param month
     * @returns {*}
     */
    this.exportNumberOfPhotosAndStoreRatings = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        if (pCountry_code == "0") {
            pCountry_code = null;
        }
        $log.debug("Get Number of photos and store ratings(", pCountry_code, year, month, ")");

        $request.fileDownloadRequest("/api-v2/mkey/stats/userstats/" + year + "/" + month, { country_code: pCountry_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save as: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };

    /**
     * Download
     * @param pCountry_code
     * @param year
     * @param month
     * @returns {*}
     */
    this.exportSob_qty = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        if (pCountry_code == "0") {
            pCountry_code = null;
        }
        $log.debug("Get Sob_qty(", pCountry_code, year, month, ")");

        $request.fileDownloadRequest("/api-v2/mkey/stats/sob/qty/" + year + "/" + month, { country_code: pCountry_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save as: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };

    /**
     * Download
     * @param pCountry_code
     * @param year
     * @param month
     * @returns {*}
     */
    this.exportSob_amt = function(pCountry_code, year, month) {
        var deferred = $q.defer();
        if (pCountry_code == "0") {
            pCountry_code = null;
        }
        $log.debug("Get sob_amt(", pCountry_code, year, month, ")");

        $request.fileDownloadRequest("/api-v2/mkey/stats/sob/amt/" + year + "/" + month, { country_code: pCountry_code })
            .then(function(data) {
                saveFile(data.content, data.name);
                $log.debug("File save as: %s", data.name);
                deferred.resolve();
            });
        return deferred.promise;
    };
}]);