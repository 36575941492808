app.factory('$Services', ["$log", "$q", "$http", "$sessionStorage", "$localStorage", "$cookies", "$state", "$rootScope", "$mdToast", "$mdDialog", "$window", function($log, $q, $http, $sessionStorage, $localStorage, $cookies, $state, $rootScope, $mdToast, $mdDialog, $window) {
    var _service = null;
    var that = {};

    function b64EncodeUnicode(str) {
        return $window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    function b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call($window.atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    that.getServices = function() {
        if (_service === null) {
            $log.debug("get service from $http");
            var deferred = $q.defer();
            $http({
                method: "post",
                url: "api/services",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                data: $.param({
                    sso: S.SSO()
                })

            }).then(function(response) {
                var data = response.data;
                deferred.resolve(data.result);
                _service = data.result;
            }, function(msg) {
                deferred.reject(msg);
                $log.error(msg, code);
            });

            return deferred.promise;
        } else {
            $log.debug("get service from global value");
            return _service;
        }
    };
    that.getUser = function() {
        if ($sessionStorage.user !== undefined) {
            $rootScope.loggedInUserId = $sessionStorage.user._id;
        }
        //$log.debug("getUser: ", $sessionStorage.user.users_name);
        return $sessionStorage.user;
    };
    that.setUser = function(user) {
        $log.debug("Save user:", user._id);
        $rootScope.loggedInUserId = user._id;
        $rootScope.loggedInUserRank = user.rank;
        //S.saveUser(user);
        $sessionStorage.user = user;
    };
    that.deleteUser = function() {
        $log.debug("Delete user");
        delete $sessionStorage.user;
    };
    that.getSSO = function() {
        var sso = $cookies.get("sso");
        return sso !== null ? sso : '';
    };

    that.setAccountChooser = function (id, email, name) {
        var idsBase64 = $localStorage.ACCOUNTS;
        var ids = idsBase64 ? JSON.parse(b64DecodeUnicode(idsBase64)) : {};

        ids[id] = {};
        ids[id].name = name;
        ids[id].email = email;
        ids[id].last = new Date();

        $localStorage.ACCOUNTS = b64EncodeUnicode(JSON.stringify(ids));
    };

    that.getAccounts = function () {
        var idsBase64 = $localStorage.ACCOUNTS;
        var ids = idsBase64 ? JSON.parse(b64DecodeUnicode(idsBase64)) : {};

        return ids;
    };

    that.deleteAccounts = function (id) {
        var idsBase64 = $localStorage.ACCOUNTS;
        var ids = idsBase64 ? JSON.parse(b64DecodeUnicode(idsBase64)) : {};

        delete ids[id];

        $localStorage.ACCOUNTS = b64EncodeUnicode(JSON.stringify(ids));
    };

    /*
     * Megvizsgálja, hogy a user admin-e
     */
    that.isLoggedInUserAdmin = function() {
        var _userRank = that.getUser().rank;
        var _ret = false;
        if (_userRank == "AdminRole" || _userRank == "RootRole") {
            _ret = true;
        }

        return _ret;
    };
    that.setSSO = function(sso) {
        S.saveSSO(sso);
        //$cookies.put("sso", sso, { expires : '5' });
    };
    that.login = function(e) {
        $log.info("Login...");
        //ToDo: át kell írni más formára a logint!!!
        e.preventDefault();
        var email = $("#login form input[name=email]").val();
        var password = $("#login form input[name=password]").val();
        var hash = S.hash(email, password);
        var devicename = navigator.userAgent;
        $http({
            method: "post",
            url: "api/login",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: $.param({
                'email': email,
                'hash': hash,
                'vendorid': '',
                'devicename': devicename,
                'imsi': ''
            })
        }).then(function(response) {
            var data = response.data;
            if (S.OK(data)) {
                that.setSSO(data.result.sso);
                that.setUser(data.result.user);
                $state.go($state.current, {}, { reload: true });

                //ToDo: undi de muszáj
                $("#login").hide();
            }
        });
    };
    that.forgotPassword = function() {
        $state.go('forgotPassword');
    };
    that.showLogin = function() {
        if (!that.getUser()) {
            $("#login").fadeIn(1000);
            $("#forgotPassword").hide();
            $("#tryToken").hide();
            $("#login form").unbind('submit');
            $("#login form").submit(that.login);
            $("#login form .forgPassLink").click(function() {
                that.forgotPassword();
            });
        }
    };
    that.checkOK = function(r, s) {
        if (r === null || r.status === null) return false;
        var alertNeeded = r.status != "OK";
        var loginNeeded = s == 401 || s == 411;
        if (alertNeeded) {
            var message = CONFIG.VALIDATION_STRING[r.result.message] ? CONFIG.VALIDATION_STRING[r.result.message] : r.result.message;
            alertify.alert(message);
        }
        if (loginNeeded) {
            $cookies.remove('sso');
            $sessionStorage.$reset();
            S.showLogin();
        }
        return !(alertNeeded || loginNeeded);
    };

    /*
     * @param: permArray (Array[])
     * Megvizsgálja, hogy a user jogosultsága benne van e a menü jogosultságában
     */
    that.checkPermission = function(permArray) {
        var _userRank = that.getUser().rank;
        var _ret = false;
        for (var i in permArray) {
            if (permArray[i] == _userRank || permArray[i] == "All") {
                _ret = true;
            }
        }

        return _ret;
    };

    that.getChannelByCountry = function(countryCode) {
        var deferred = $q.defer();
        if (countryCode !== undefined) {
            $http({
                method: "post",
                url: "api/channels",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                params: {
                    'country_code': countryCode
                },
                data: $.param({
                    'sso': S.SSO()
                })
            }).then(function(response) {
                var data = response.data;
                if (S.OK(data)) {
                    deferred.resolve(data.result[countryCode]);
                } else {
                    $log.error(data.result.code + ": " + data.result.message);
                    deferred.reject('Status: error');
                }
            }, function(msg, code) {
                deferred.reject(msg);
                $log.error(msg, code);
            });
        } else {
            deferred.reject('getChannelByCountry: undefined parameter');
        }
        return deferred.promise;
    };

    that.toastMessage = function(text, type) {

        var toast = $mdToast.simple()
            .textContent(text)
            .action('OK')
            .highlightAction(false)
            .hideDelay('6000')
            .position('right');
        if (type !== undefined) {
            toast.theme(type + "-toast");
        }
        $mdToast.show(toast);
    };

    that.yearList = function() {
        var _arr = [];
        var _firstYear = 2015;
        var _actualYear = new Date().getFullYear();
        for (var i = _actualYear; i >= _firstYear; i--) {
            _arr.push(i);
        }
        return _arr;
    };

    that.getDays = function() {
        return CONFIG.DAYS;
    };

    that.getStatements = function(statement) {
        var statements = ['Cancelled', 'Reserved', 'Ideiglenes'];
        return statements[statement];
    };

    that.checkResponse = function(data, callback) {
        var deferred = $q.defer();
        if (data.result == "OK") {
            deferred.resolve(data);
        } else if (data.result == "CONFIRM_NEEDED") {
            function DialogController($scope, $mdDialog, data) {
                $scope.message = data.message;
                $scope.options = data.options;

                $scope.hide = function(params) {
                    if (params) {
                        $mdDialog.hide(params);
                    } else {
                        $mdDialog.cancel();
                    }

                };

                $scope.cancel = function() {
                    $mdDialog.cancel();
                };
            }

            $mdDialog.show({
                    controller: DialogController,
                    templateUrl: '/frontend/ui/template/confirmNeeded.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    multiple: true,
                    locals: {
                        data: data
                    }
                })
                .then(function (p) {
                    callback(p);
                }, function () {
                });
        } else{
            deferred.reject(data);
            $log.error("Request error (%s): %s", data.result, data.reason);
            that.toastMessage(data.message, 'error');
        }
        return deferred.promise;
    };


    that.checkResponse_v1 = function(data) {
        var deferred = $q.defer();
        if (data.status == "OK") {
            deferred.resolve(data);
        } else{
            deferred.reject(data);
            $log.error("Request error (%s): %s", data.result, data.reason);

            var message = CONFIG.VALIDATION_STRING[data.result.message] ? CONFIG.VALIDATION_STRING[data.result.message] : data.result.message;
            that.toastMessage(message, 'error');
        }
        return deferred.promise;
    };

    that.hash = function(email, password){
        var sourceC = email.toLowerCase().replace(/[^a-z0-9]/ig, '') + password + "12345hat7";
        return Crypto.SHA256(sourceC) + Crypto.SHA1(sourceC);
    };
    return that;
}]);